import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import SubjectsTable from './SubjectsTable';
import { setCurrentSubjectPage, setSubject } from '../../redux/slices/subjects';
import Loader from '../Loader';
import { cpuUsage } from 'process';
import { isSetIterator } from 'util/types';

interface Attempt {
  id: number;
  month: string;
  year: number;
}

interface Subject {
  id: number;
  title: string;
  description: string;
}

interface CourseType {
  id: number;
  title: string;
  description: string;
  subjects: Subject[];
  attempts: Attempt[];
}

interface Course {
  name: string;
  description: string;
  course_type: CourseType[];
}

function Subjects() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.subjects.previous);
  const next = useAppSelector((state: RootState) => state.subjects.next);
  const current = useAppSelector((state: RootState) => state.subjects.current);
  const dispatch = useAppDispatch();
  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isSubjectLoading, setIsSubjectLoading] = useState(false);
  const [courses, setCourses] = useState<Course[]>([]);
  const [activeCourse, setActiveCourse] = useState<Course>();
  const [activeCourseType, setActiveCourseType] = useState<CourseType>();

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      setIsCourseLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`courses/`, config)
        .then((res) => {
          setCourses(res.data.results);
          setActiveCourse(res.data.results[0]);
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        })
        .finally(() => {
          setIsCourseLoading(false);
        });
    }
  }, [token, dispatch, navHandler]);

  useEffect(() => {
    if (activeCourse) {
      setActiveCourseType(activeCourse.course_type[0]);
    }
  }, [activeCourse]);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else if (activeCourseType?.id) {
      setIsSubjectLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      BaseUrl.get(
        `subjects-by-course-type/?course_type=${activeCourseType.id}&page=${current}`,
        config
      )
        .then((res) => {
          const curr = res.data?.current || 1;
          const modifiedData = {
            count: res.data.count || 0,
            previous: res.data.previous || null,
            next: res.data.next || null,
            results: res.data || [],
            current: curr,
          };
          dispatch(setSubject(modifiedData));
        })
        .catch((err) => {
          if (err.response && err.response.status === 403) {
            dispatch(setAccess(''));
          }
          console.log('Error:', err);
          const modifiedData = {
            count: 0,
            previous: null,
            next: null,
            results: [],
            current: 1,
          };
          dispatch(setSubject(modifiedData));
        })
        .finally(() => {
          setIsSubjectLoading(false);
        });
    }
  }, [current, token, activeCourseType, dispatch, navHandler]);

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Subjects" />
      {isSubjectLoading ? (
        <Loader />
      ) : (
        <div className="col-span-1 md:col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
          <header className="flex flex-col md:flex-row gap-5 justify-between px-4 md:px-11 py-6 w-full border-b border-solid border-zinc-200">
            <div className="flex flex-col md:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
              <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
                Subjects
              </h1>
              <nav className="flex gap-2.5 mx-auto my-auto text-base leading-6 text-gray-500">
                <a href="/" className=" text-blue-600">
                  Home
                </a>
                <span>›</span>
                <p className="text-blue-600">Subjects</p>
                <span>›</span>
                <span>Subjects</span>
              </nav>
            </div>
          </header>

          <div className="p-4 md:p-8">
            <h2 className="text-2xl font-bold mb-4">Courses</h2>
            <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
              {courses.map((course, index) => (
                <p
                  key={index}
                  onClick={() => setActiveCourse(course)}
                  className={`p-2 rounded-full border-2 cursor-pointer text-center transition-all duration-200 ease-in-out ${
                    activeCourse === course
                      ? 'border-blue-600 bg-blue-200 text-blue-700 shadow-md transform hover:scale-105'
                      : 'border-black hover:bg-gray-100 hover:shadow-lg'
                  }`}
                >
                  {course.name}
                </p>
              ))}
            </div>

            {activeCourse && (
              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">
                  Course Types for {activeCourse.name}
                </h2>
                <div className="grid grid-cols-2 md:grid-cols-6 gap-4">
                  {activeCourse.course_type.map((courseType, index) => (
                    <p
                      key={index}
                      onClick={() => setActiveCourseType(courseType)}
                      className={`p-2 rounded-full border-2 cursor-pointer text-center transition-all duration-200 ease-in-out ${
                        activeCourseType?.title === courseType.title
                          ? 'border-blue-600 bg-blue-200 text-blue-700 shadow-md transform hover:scale-105'
                          : 'border-black hover:bg-gray-100 hover:shadow-lg'
                      }`}
                    >
                      {courseType.title}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>

          {/* This section will show the loader only over the table */}
          <main className="flex flex-col p-4 md:p-8 w-full">
            <div className="relative">
              {isSubjectLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                  <Loader />
                </div>
              )}
              <SubjectsTable />
            </div>
          </main>

          <div className="flex gap-2 w-full justify-center items-center m-4">
            {prev && (
              <button
                onClick={() => dispatch(setCurrentSubjectPage(current - 1))}
                className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${
                  isCourseLoading
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-300'
                }`}
                disabled={isSubjectLoading}
              >
                {'<'}
              </button>
            )}
            <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-default">
              {current}
            </div>
            {next && (
              <button
                onClick={() => dispatch(setCurrentSubjectPage(current + 1))}
                className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${
                  isSubjectLoading
                    ? 'opacity-50 cursor-not-allowed'
                    : 'hover:bg-gray-300'
                }`}
                disabled={isSubjectLoading}
              >
                {'>'}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Subjects;
