import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { setCurrentEmployeePage } from '../../redux/slices/employees';
import EmployeesTable from './EmployeesTable';
import { setEmployees } from '../../redux/slices/employees';
import Loader from '../Loader';

function Employees() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.employee.previous);
  const next = useAppSelector((state: RootState) => state.employee.next);
  const current = useAppSelector((state: RootState) => state.employee.current);
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>();
  const [isFiltering, setIsFiltering] = useState(false);

  const fetchEmployees = () => {
    if (!token) {
      navHandler('/login');
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let params = new URLSearchParams();

    if (isFiltering) {
      params.append('page', '1');
    } else {
      params.append('page', String(current));
    }

    if (number) params.append('phone_number', number);
    if (name) params.append('name', name);
    if (role) params.append('role', role);

    setIsLoading(true);

    BaseUrl.get(`filter-employees/?${params.toString()}`, config)
      .then((res) => {
        const employeesData = res.data;
        dispatch(setEmployees(employeesData));
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          dispatch(setAccess(''));
        }
        if (err.response?.status === 404) {
          dispatch(setCurrentEmployeePage(1));
        }
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      window.setTimeout(() => {
        setIsFiltering(true);
        dispatch(setCurrentEmployeePage(1));
      }, 300)
    );
  };

  useEffect(() => {
    handleInputChange();
  }, [number, name, role]);

  useEffect(() => {
    if (isFiltering) {
      fetchEmployees();
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      fetchEmployees();
    }
  }, [current]);

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    }
  }, [isFiltering]);

  const clearFilters = () => {
    setNumber('');
    setName('');
    setRole('');
    setIsFiltering(false);
    fetchEmployees();
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Employees" />
      <div className="col-span-1 lg:col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex flex-col lg:flex-row gap-5 justify-between px-5 lg:px-11 py-6 w-full border-b border-solid border-zinc-200">
          <div className="flex flex-col lg:flex-row gap-2.5 lg:gap-5 justify-between">
            <h1 className="text-center sm: text-2xl leading-7 border-r border-solid border-zinc-300 text-neutral-800 pr-0 lg:pr-4">
              Employees
            </h1>
            <nav className="flex mx-auto sm: gap-2.5 text-base leading-6 text-gray-500">
              <a href="/" className=" text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Employees</p>
              <span>›</span>
              <span>Employees</span>
            </nav>
          </div>
          <button
            onClick={() => navHandler('create')}
            className="flex gap-2.5 items-center justify-center p-4 text-base text-center text-white bg-green-600 rounded"
          >
            <img src="/addOrder.svg" alt="Add Employee" />
            <span className="leading-[150%]">Add Employee</span>
          </button>
        </header>

        <main className="flex flex-col p-5 lg:p-8 w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-700">Filters</p>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-center">
              <div>
                <label
                  htmlFor="number"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Phone number:
                </label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="Enter phone number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Name:
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter username"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3 text-xs my-4 w-full gap-4 font-extrabold">
            {['admin', 'teacher', 'doubt_solver'].map((roleOption) => (
              <p
                key={roleOption}
                onClick={() => setRole(roleOption)}
                className={`p-2 rounded-full border cursor-pointer text-center ${
                  role === roleOption
                    ? 'border-blue-600 bg-blue-200 text-blue-700'
                    : 'border-black'
                }`}
              >
                {roleOption
                  .replace('_', ' ')
                  .replace(/\b\w/g, (char) => char.toUpperCase())}
              </p>
            ))}
          </div>

          <div className="relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                <Loader />
              </div>
            )}
            <EmployeesTable role={role} />
          </div>
        </main>

        {!isLoading && (
          <div className="flex gap-2 w-full justify-center items-center m-4">
            {prev && (
              <button
                onClick={() => dispatch(setCurrentEmployeePage(current - 1))}
                className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
                {'<'}
              </button>
            )}
            <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
              {current}
            </div>
            {next && (
              <button
                onClick={() => dispatch(setCurrentEmployeePage(current + 1))}
                className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
                {'>'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Employees;
