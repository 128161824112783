import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { generateFilenameWithTimestamp } from '../../utils/CreateFileName';
import { PresignedUrlBaseLength } from '../../PresignedUrlBaseLength';

function CreateTestSeries() {
  interface attemptInterface {
    id: number;
    month?: string;
    year?: string;
  }

  interface courseTypeInterface {
    id?: number;
    title?: string;
    attempts?: Array<attemptInterface>;
  }

  interface testSeriesTypeInterface {
    id?: number;
    title?: string;
  }

  interface testSeriesInterface {
    discounted_price: number;
    mrp_price: number;
    title: string;
    description: string;
    course_type: courseTypeInterface;
    status: boolean;
    test_series_type: testSeriesTypeInterface;
    test_series_attempts: Array<attemptInterface>;
    valid_from: string;
    valid_till: string;
    fees_structure: string;
    syllabus: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  // const [testSeries, setTestSeries] = useState<testSeriesInterface>({
  //   discounted_price: 0,
  //   mrp_price: 0,
  //   title: '',
  //   description: '',
  //   course_type: {},
  //   status: false,
  //   test_series_type: {},
  //   test_series_attempts: [],
  //   valid_from: '',
  //   valid_till: '',
  //   fees_structure: '',
  //   syllabus: '',
  // });
  const [testSeriesNew, setTestSeriesNew] = useState<testSeriesInterface>({
    discounted_price: 0,
    mrp_price: 0,
    title: '',
    description: '',
    course_type: {},
    status: false,
    test_series_type: {},
    test_series_attempts: [],
    valid_from: '',
    valid_till: '',
    fees_structure: '',
    syllabus: '',
  });
  const [courseType, setCourseType] = useState<courseTypeInterface[]>([]);
  const [courseTypeId, setCourseTypeId] = useState<number>();
  const [selectedCourseId, setSelectedCourseId] = useState<string>('');
  const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>(
    []
  );
  const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>();
  const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
  const [availableAttempts, setAvailableAttempts] = useState<
    attemptInterface[]
  >([]);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [selectedSyllabus, setSelectedSyllabus] = useState<File | undefined>(
    undefined
  );
  const [feesUploadProgress, setFeesUploadProgress] = useState(0);
  const [isFeesUploading, setIsFeesUploading] = useState(false);
  const [syllabusUploadProgress, setSyllabusUploadProgress] = useState(0);
  const [isSyllabusUploading, setIsSyllabusUploading] = useState(false);
  const [isCreating, setIsCreating] = useState(false)
  // const toggleListVisibility = () => {
  //   setIsListVisible(!isListVisible);
  // };

  // const handleCheckboxChange = (
  //   event: { target: { checked: any } },
  //   attemptId: number
  // ) => {
  //   if (event.target.checked) {
  //     setSelectedAttempts([...selectedAttempts, attemptId]);
  //   } else {
  //     setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
  //   }
  // };

  // const removeSelectedAttempt = (attemptId: number) => {
  //   setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
  // };

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseType(res.data);
          setAvailableAttempts(res.data.results[0].attempts);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          console.log(err);
        });
      BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
        .then((res) => {
          setTestSeriesType(res.data);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const selectedCourse = courseType.find(
      (course) => course.id == (courseTypeId || courseType[0].id)
    );
    setAvailableAttempts(selectedCourse?.attempts || []);
    setSelectedAttempts([]);
  }, [courseTypeId]);

  const handleError = (err: any) => {
    if (err.response?.status === 403) dispatch(setAccess(''));
    console.error('Error:', err);
  };

  const uploadFile = async (
    file: File,
    type: 'fees' | 'syllabus',
    setUploadProgress: any,
    setIsUploading: any
  ) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const endpoint =
      type === 'fees'
        ? 'fees-structure-presigned-url'
        : 'syllabus-presigned-url';
    const presignedUrl = await BaseUrl.get(
      `${endpoint}/?object_key=${generateFilenameWithTimestamp(file.name)}`,
      config
    )
      .then((res) => res.data.url)
      .catch((err) => {
        console.error(`Error getting presigned URL for ${type}:`, err);
        throw new Error('Unable to get presigned URL');
      });

    setIsUploading(true);
    const uploadResponse = await axios.put(presignedUrl, file, {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total || 1;
        const progress = Math.round((progressEvent.loaded * 100) / total);
        setUploadProgress(progress);
      },
    });
    if (uploadResponse.status === 200) {
      const endIndex = presignedUrl.indexOf('?');
      const extractedString = presignedUrl.substring(PresignedUrlBaseLength, endIndex);
      const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
      return (baseUrl + extractedString)
    } else {
      alert(`${type === 'fees' ? 'Fees structure' : 'Syllabus'} upload failed`);
      throw new Error(`${type} upload failed`);
    }
  };

  const createTestSeries = async () => {
    if (!selectedAttempts[0]) {
      toast.error('Please select an attempt before creating the test series');
      return;
    }

    let feesStructure = selectedFile
      ? await uploadFile(
          selectedFile,
          'fees',
          setFeesUploadProgress,
          setIsFeesUploading
        )
      : '';
    let syllabus = selectedSyllabus
      ? await uploadFile(
          selectedSyllabus,
          'syllabus',
          setSyllabusUploadProgress,
          setIsSyllabusUploading
        )
      : '';

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await BaseUrl.post(
        `admin/test-series/`,
        {
          course_type_id: testSeriesNew.course_type.id,
          test_series_type_id: testSeriesNew.test_series_type?.id || testSeriesType[0]?.id,
          title: testSeriesNew.title,
          description: testSeriesNew.description,
          valid_from: testSeriesNew.valid_from,
          valid_till: testSeriesNew.valid_till,
          discounted_price: testSeriesNew.discounted_price || 0,
          mrp_price: testSeriesNew.mrp_price || 0,
          status: testSeriesNew.status || false,
          test_series_attempts_ids: selectedAttempts[0] || null,
          fees_structure: feesStructure || testSeriesNew.fees_structure,
          syllabus: syllabus || testSeriesNew.syllabus,
        },
        config
      );

      setTestSeriesNew(response.data);
      navHandler(`/test-series`);
      toast.success('Created Successfully');
    } catch (err) {
      handleError(err);
    } finally {
      // Clear selected files after creation
      setSelectedFile(undefined);
      setSelectedSyllabus(undefined);
      setFeesUploadProgress(0);
      setIsFeesUploading(false);
      setSyllabusUploadProgress(0);
      setIsSyllabusUploading(false);
      setIsCreating(false)
    }
  };

  const formatDateForInput = (dateString: string) => {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const convertToBackendFormat = (localDateTime: string) => {
    const date = new Date(localDateTime);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSyllabusChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedSyllabus(file);
  };

  useEffect(() => {
    if (
      testSeriesNew.course_type.attempts &&
      testSeriesNew.course_type.attempts.length > 0 &&
      selectedAttempts.length === 0
    ) {
      setSelectedAttempts([testSeriesNew.course_type.attempts[0].id]);
    }
  }, [testSeriesNew.course_type.attempts, selectedAttempts]);

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Test series" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Test Series
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Testseries</p>
              <span>›</span>
              <span>Test series - </span>
              <span>Create</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
            <div className="w-full text-sm grid grid-cols-5 items-center gap-4">
              <span className="font-extrabold">Title :</span>
              <input
                onChange={(e) =>
                  setTestSeriesNew((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
                className="col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg font-sans"
                value={testSeriesNew.title}
                placeholder="CA Final Detailed Test Series"
              />
            </div>

            <div className="w-full text-sm grid grid-cols-5 items-center gap-4">
              <span className="font-extrabold">Description :</span>
              <textarea
                onChange={(e) =>
                  setTestSeriesNew((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
                className="h-[15vh] col-span-4 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg font-sans"
                value={testSeriesNew.description}
                placeholder="CA Final Detailed Test Series description..."
              />
            </div>

            {testSeriesType && (
              <div className="w-full text-sm grid grid-cols-5 items-center gap-4">
                <span className="font-extrabold">Test Series type :</span>
                <select
                  value={testSeriesTypeId}
                  onChange={(e) => {
                    const selectedTestSeriesTypeId = parseInt(e.target.value);
                    const selectedTestSeriesType = Object.values(
                      testSeriesType
                    ).find((type) => type.id === selectedTestSeriesTypeId);

                    setTestSeriesNew((prev) => ({
                      ...prev,
                      test_series_type: selectedTestSeriesType || {},
                    }));

                    setTestSeriesTypeId(selectedTestSeriesTypeId);
                  }}
                  className="w-full col-span-4 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                >
                  <option value="" disabled>
                    Select a test series type
                  </option>
                  {Object.entries(testSeriesType).map(
                    ([key, testSeriesType]) =>
                      testSeriesType &&
                      testSeriesType.id && (
                        <option
                          className="w-full"
                          key={key}
                          value={testSeriesType.id}
                        >
                          {testSeriesType.title}
                        </option>
                      )
                  )}
                </select>
              </div>
            )}

            {courseType && (
              <div className="w-full text-sm grid grid-cols-5 items-center gap-4">
                <span className="font-extrabold">Course type :</span>
                <select
                  value={selectedCourseId} // Set value to selectedCourseId state
                  onChange={(e) => {
                    const newCourseId = parseInt(e.target.value);
                    const selectedCourse = Object.values(courseType).find(
                      (course) => course.id === newCourseId
                    );
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      course_type: selectedCourse || {},
                    }));
                    setSelectedAttempts([]);
                    setSelectedCourseId(newCourseId.toString()); // Convert to string before updating state
                  }}
                  className="w-full col-span-4 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                >
                  <option value="" disabled>
                    Select a course
                  </option>
                  {Object.entries(courseType).map(
                    ([key, course]) =>
                      course &&
                      course.id && (
                        <option className="w-full" key={key} value={course.id}>
                          {course.title}
                        </option>
                      )
                  )}
                </select>
              </div>
            )}
            <div className="w-full text-sm grid grid-cols-5 gap-4">
              <span className="font-extrabold">Attempts :</span>
              <div className="col-span-4 w-full">
                {testSeriesNew.course_type.attempts &&
                testSeriesNew.course_type.attempts.length > 0 ? (
                  <select
                    className="w-full p-2 border border-gray-400 rounded-lg bg-gray-200"
                    value={
                      selectedAttempts.length > 0
                        ? selectedAttempts[0].toString()
                        : ''
                    }
                    onChange={(e) => {
                      const newSelection = Number(e.target.value);
                      setSelectedAttempts([newSelection]);
                    }}
                  >
                    <option value="" disabled>
                      Select an attempt
                    </option>
                    {testSeriesNew.course_type.attempts.map((attempt) => (
                      <option key={attempt.id} value={attempt.id.toString()}>
                        {attempt.month}, {attempt.year}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span className="text-xs text-gray-500">
                    No attempts available
                  </span>
                )}
              </div>
            </div>

            <div className="w-full text-sm flex gap-20">
              <div className="flex gap-20 items-center">
                <span className="font-extrabold">Valid from :</span>
                <input
                  type="date"
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      valid_from: convertToBackendFormat(e.target.value),
                    }))
                  }
                  className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                  value={formatDateForInput(testSeriesNew.valid_from)}
                  max={testSeriesNew.valid_till}
                />
              </div>
              <div className="flex gap-5 items-center">
                <span className="font-extrabold">Valid till :</span>
                <input
                  type="date"
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      valid_till: convertToBackendFormat(e.target.value),
                    }))
                  }
                  className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                  value={formatDateForInput(testSeriesNew.valid_till)}
                  min={testSeriesNew.valid_from}
                />
              </div>
            </div>

            <div className="w-full text-sm grid grid-cols-5 items-center gap-4">
              <span className="font-extrabold">Status :</span>
              <select
                onChange={(e) =>
                  setTestSeriesNew((prev) => ({
                    ...prev,
                    status: e.target.value === 'active',
                  }))
                }
                className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                value={testSeriesNew.status ? 'active' : 'inactive'}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <div className="w-full text-sm flex gap-9">
              <div className="flex gap-10 items-center">
                <span className="font-extrabold">Discounted price :</span>
                <input
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      discounted_price: parseFloat(e.target.value),
                    }))
                  }
                  className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                  value={testSeriesNew.discounted_price}
                />
              </div>

              <div className="flex gap-5 items-center">
                <span className="font-extrabold">MRP price :</span>
                <input
                  onChange={(e) =>
                    setTestSeriesNew((prev) => ({
                      ...prev,
                      mrp_price: parseFloat(e.target.value),
                    }))
                  }
                  className="p-2 outline-none border border-1 border-zinc-300 rounded-lg"
                  value={testSeriesNew.mrp_price}
                />
              </div>
            </div>

            <pre className="w-full grid grid-cols-3 gap-3 items-center">
              <span className="font-extrabold">Fees Structure:</span>
              <div className="col-span-2 w-3/4 ">
                <div className="flex flex-col gap-3">
                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    id="feesStructure"
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="feesStructure"
                    className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                  >
                    {testSeriesNew.fees_structure || selectedFile
                      ? 'Replace PDF'
                      : 'Upload PDF'}
                  </label>
                  {isFeesUploading && (
                    <div className="w-full bg-gray-200 rounded-lg h-2">
                      <div
                        className="bg-blue-500 h-2 rounded-lg"
                        style={{ width: `${feesUploadProgress}%` }}
                      ></div>
                    </div>
                  )}
                </div>
                {selectedFile ? (
                  <p className="mt-2 text-center">
                    <a
                      href={URL.createObjectURL(selectedFile)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {selectedFile.name}
                    </a>
                  </p>
                ) : (
                  testSeriesNew.fees_structure && (
                    <p className="mt-2 text-center">
                      <a
                        href={testSeriesNew.fees_structure}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {testSeriesNew.fees_structure}
                      </a>
                    </p>
                  )
                )}
              </div>
              <span className="font-extrabold">Syllabus:</span>
              <div className="col-span-2 w-3/4">
                <div className="flex flex-col gap-3">
                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    id="syllabus"
                    onChange={handleSyllabusChange}
                  />
                  <label
                    htmlFor="syllabus"
                    className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                  >
                    {testSeriesNew.syllabus || selectedSyllabus
                      ? 'Replace PDF'
                      : 'Upload PDF'}
                  </label>
                  {isSyllabusUploading && (
                    <div className="w-full bg-gray-200 rounded-lg h-2">
                      <div
                        className="bg-blue-500 h-2 rounded-lg"
                        style={{ width: `${syllabusUploadProgress}%` }}
                      ></div>
                    </div>
                  )}
                </div>
                {selectedSyllabus ? (
                  <p className="mt-2 text-center">
                    <a
                      href={URL.createObjectURL(selectedSyllabus)}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      {selectedSyllabus.name}
                    </a>
                  </p>
                ) : (
                  testSeriesNew.syllabus && (
                    <p className="mt-2 text-center">
                      <a
                        href={testSeriesNew.syllabus}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {testSeriesNew.syllabus}
                      </a>
                    </p>
                  )
                )}
              </div>
            </pre>

            {isCreating ? <button
                  className="p-2 w-1/4 mx-auto mt-4 rounded-lg bg-blue-600 text-lg font-semibold text-white bg-opacity-50"
                >
                  Creating
                </button> : testSeriesNew.title &&
              testSeriesNew.description &&
              testSeriesNew.valid_from &&
              testSeriesNew.valid_till &&
              (selectedFile || selectedSyllabus) && (
                <button
                  onClick={()=>(setIsCreating(true),createTestSeries())}
                  className="p-2 w-1/4 mx-auto mt-4 rounded-lg bg-blue-600 text-lg font-semibold text-white"
                >
                  Create
                </button>
              )}
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateTestSeries;
