import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import TestTable from './TestTable';
import { setCurrentTestPage, setTest } from '../../redux/slices/tests';
import Loader from '../Loader';

function Tests() {
  interface TestTypes {
    id: string;
    title: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.test.previous);
  const next = useAppSelector((state: RootState) => state.test.next);
  const current = useAppSelector((state: RootState) => state.test.current);

  const [testSeriesType, setTestSeriesType] = useState('');
  const [testSeriesTypes, setTestSeriesTypes] = useState<TestTypes[]>([]);
  const [courseType, setCourseType] = useState('');
  const [courseTypes, setCourseTypes] = useState<TestTypes[]>([]);
  const [subject, setSubject] = useState('');
  const [subjects, setSubjects] = useState<TestTypes[]>([]);

  const [loading, setLoading] = useState(true); // Add loading state
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true); // Start loading

      // Fetch tests
      BaseUrl.get(
        `filter-tests/?page=${current}&test_series_type=${testSeriesType}&course_type=${courseType}&subject=${subject}`,
        config
      )
        .then((res) => {
          const curr = res.data.current;
          const modifiedData = {
            ...res.data,
            current: curr,
          };
          dispatch(setTest(modifiedData));
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        });

      // Fetch test series types
      BaseUrl.get(`admin/non-paginated-test-series-type`, config)
        .then((res) => {
          setTestSeriesTypes(res.data);
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        });

      // Fetch course types
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseTypes(res.data);
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        });

      // Fetch subjects
      BaseUrl.get(`admin/non-paginated-subject`, config)
        .then((res) => {
          setSubjects(res.data);
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        })
        .finally(() => {
          setLoading(false); // End loading
        });
    }
  }, [
    current,
    subject,
    testSeriesType,
    courseType,
    token,
    navHandler,
    dispatch,
  ]);

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Tests" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Tests
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">TestSeries</p>
              <span>›</span>
              <span>Test</span>
            </nav>
          </div>
          <button
            onClick={() => navHandler('create')}
            className="flex gap-2.5 items-center justify-center p-4 text-base text-center text-white bg-green-600 rounded"
          >
            <img src="/addOrder.svg" alt="Add Test" />
            <span className="leading-[150%]">Add Test</span>
          </button>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <>
            <div className="my-6">
              <div className="flex justify-between items-center mb-4">
                <p className="text-lg font-semibold text-gray-700">Filters</p>
                <button
                  onClick={() => {
                    setTestSeriesType('');
                    setCourseType('');
                    setSubject('');
                  }}
                  className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  Clear All Filters
                </button>
              </div>
              <div className="grid grid-cols-3 md:grid-cols-3 gap-6">
                <div>
                  <label
                    htmlFor="testSeriesType"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Test Series Type:
                  </label>
                  <select
                    id="testSeriesType"
                    name="testSeriesType"
                    value={testSeriesType}
                    onChange={(e) => setTestSeriesType(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {testSeriesTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="courseType"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Course Type:
                  </label>
                  <select
                    id="courseType"
                    name="courseType"
                    value={courseType}
                    onChange={(e) => setCourseType(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {courseTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    htmlFor="subjects"
                    className="block text-sm font-medium text-gray-700 mb-2"
                  >
                    Subject:
                  </label>
                  <select
                    id="subjects"
                    name="subjects"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">----</option>
                    {subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="relative">
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                  <Loader />
                </div>
              )}
              <TestTable />
            </div>
          </>
        </main>
        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrentTestPage(current - 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'<'}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrentTestPage(current + 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'>'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Tests;
