import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';

function SpecificOrder() {
  interface subject {
    title: string;
  }
  interface purchase {
    test_series: {
      title: string;
    };
    subjects: subject[];
  }
  interface orderType {
    user: {
      phone: number;
      name: string;
      email: string;
    };
    created_at: string;
    order_value: number;
    order_status: string;
    transaction: {
      payment_id: string;
    };
    purchases: purchase[];
  }
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [order, setOrder] = useState<orderType>({
    user: {
      phone: 0,
      name: '',
      email: '',
    },
    created_at: '',
    order_value: 0,
    order_status: 'pending',
    transaction: {
      payment_id: '',
    },
    purchases: [],
  });
  const [orderStatus, setOrderStatus] = useState<string>("");

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/orders/${id}`, config)
        .then((res) => {
          setOrder(res.data);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          if (err.response?.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }, []);

  const toSentenceCase = (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleStatusChange = (status: string) => {
    setOrderStatus(status)
  }

  useEffect(()=>{
    setOrderStatus(order.order_status)
  },[order.order_status])

  function updateOrder () {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.patch(`admin/orders/${id}`,{order_status : orderStatus},config)
        .then((res) => {
          setOrder(res.data);
        })
        .catch((err) => {
          if (err.response?.status == 403) dispatch(setAccess(''));
          if (err.response?.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }
  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Orders" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row mx-auto sm:mx-0 gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Orders
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/orders" className="text-blue-600">
                Orders
              </a>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/orders')}
              >
                Orders
              </span>
              <span>›</span>
              {status ? (
                <span
                  className="truncate max-w-[50px] sm:max-w-xs md:max-w-full"
                  title={`${order.user.phone}_${order.created_at}`}
                >
                  {order.user.phone}_{order.created_at}
                </span>
              ) : (
                <span>Not Found</span>
              )}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="flex justify-evenly gap-5 items-start p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-lg max-md:px-5 w-fit">
              <p>
                <span className="font-extrabold">User Name : </span>
                {order.user.name}
              </p>
              <p>
                <span className="font-extrabold">User Email : </span>
                {order.user.email}
              </p>
              <p>
                <span className="font-extrabold">User Phone : </span>
                {order.user.phone}
              </p>
              <p>
                <span className="font-extrabold">Transaction ID : </span>
                {order.transaction.payment_id
                  ? order.transaction.payment_id
                  : '---'}
              </p>

              <p>
                <span className="font-extrabold">Order value : </span>
                {order.order_value}
              </p>
              <p>
                <span className="font-extrabold">Order status : </span>
                <select
                  className="font-extrabold border border-gray-300 rounded p-2"
                  value={orderStatus || order.order_status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  <option value="Pending">Pending</option>
                  <option value="Completed">Completed</option>
                  <option value="Processing">Processing</option>
                  <option value="Canceled">Canceled</option>
                </select>
              </p>
              <p>
                <span className="font-extrabold">Created at : </span>
                {order.created_at}
              </p>
              {(orderStatus && orderStatus!=order.order_status) && <button onClick={updateOrder} className="p-2 mx-auto w-fit rounded-lg bg-blue-600 text-lg font-semibold text-white">Update</button>}
            </div>
            {order.purchases.length > 0 ? (
              <div className="flex flex-col gap-6 p-8 mt-4 bg-white rounded-xl shadow-lg w-fit max-md:px-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Purchases
                </h2>
                <ul className="space-y-6">
                  {order.purchases.map((purchase, index) => (
                    <li
                      key={index}
                      className="p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition"
                    >
                      <span className="text-xl font-semibold text-gray-700">
                        Test Series:{' '}
                      </span>
                      <span className="text-lg font-medium text-gray-600 mt-1">
                        {purchase.test_series.title}
                      </span>

                      <h4 className="text-lg font-semibold text-gray-700 mt-4">
                        Subjects
                      </h4>
                      <ul className="mt-2 pl-4 list-disc list-inside text-gray-600">
                        {purchase.subjects.map((subject, subIndex) => (
                          <li key={subIndex} className="py-1">
                            {toSentenceCase(subject.title)}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-lg max-md:px-5 w-fit">
                <p className="text-lg font-semibold text-gray-600">
                  No Purchases
                </p>
              </div>
            )}
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold text-center">
            No Order Found With Id : {id}
          </div>
        )}
      </div>
    </div>
  );
}

export default SpecificOrder;
