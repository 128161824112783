import React, { useEffect, useState } from 'react';
import BaseUrl from '../../BaseUrl';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { setAccess, setId, setPhone, setRole } from '../../redux/slices/auth';
import { useNavigate } from 'react-router-dom';

function Login() {
  const [number, setNumber] = useState<number>();
  const [pass, setPass] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navHandler = useNavigate();
  const token = useAppSelector((state) => state.auth.access);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validatePhoneNumber = (value: string) => {
    // Define a simple regex pattern for phone number validation
    const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
    return phonePattern.test(value);
  };

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setNumber(value);

    // Validate the phone number
    if (validatePhoneNumber(value)) {
      setError('');
    } else {
      setError('Please enter a valid 10-digit phone number');
    }
  };
  const handleLogin = () => {
    if (number && pass) {
      const config = {
        phone_number: number,
        password: pass,
      };
      setLoading(true);
      BaseUrl.post('login/', config)
        .then((res) => {
          dispatch(setAccess(res.data.access));
          dispatch(setRole(res.data.role));
          dispatch(setPhone(res.data.phone_number));
          dispatch(setId(res.data.staff_id));
          const role = res.data.role;
          if (role == 'admin') navHandler('/orders');
          if (role == 'teacher') navHandler('/submissions');
          if (role == 'doubt_solver') navHandler('/doubts');
        })
        .catch((err) => {
          if (err.response.status == 401)
            toast.error(err.response.data['detail']);
          else console.log(err);
        });
      setLoading(false);
    } else toast.error('Please enter all details');
  };

  useEffect(() => {
    if (token) navHandler('/orders');
  }, []);

  return (
    <div className="flex justify-center items-center w-full min-h-screen">
      <div className="w-full max-w-sm border border-2 border-gray-50 gap-4 flex flex-col items-center justify-center shadow-lg rounded-lg px-6 py-4">
        <p className="text-xl">Welcome</p>
        <div className="grid grid-cols-8 w-full">
          <input
            value={number}
            onChange={handleChange}
            type="tel"
            className={`col-span-7 w-full border border-1 border-gray-400 p-2 rounded-l-lg ${
              error ? 'border-red-500' : ''
            }`}
            placeholder="Phone Number"
            maxLength={10}
            required
          />
          <div className="bg-[#E9ECEF] rounded-r-lg border border-1 border-gray-400 p-2 flex justify-center items-center">
            <img src="/person.svg" alt="number" />
          </div>
          {error && number && (
            <p className="col-span-8 text-red-500 mt-2">{error}</p>
          )}
        </div>
        <div className="grid grid-cols-8 w-full">
          <input
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            className="col-span-7 w-full border border-1 border-gray-400 p-2 rounded-l-lg"
            placeholder="Password"
            required
          />
          <div
            className="bg-[#E9ECEF] rounded-r-lg border border-1 border-gray-400 p-2 flex justify-center items-center cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            <img
              src={showPassword ? '/openEye.svg' : '/closedEye.svg'}
              alt={showPassword ? 'hide password' : 'show password'}
            />
          </div>
        </div>
        <button
          onClick={handleLogin}
          className={`bg-[#007BFF] w-full rounded-lg text-white text-lg p-2 text-center ${
            loading || error ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading || !!error}
        >
          Login
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
