import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddCoupon() {
  interface couponType {
    id: string;
    title: string;
    discount_percent: number;
    code: string;
    status: boolean;
    type: string;
  }
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [updatedCoupon, setUpdatedCoupon] = useState<couponType>({
    id: '',
    title: '',
    discount_percent: 0,
    code: '',
    status: false,
    type: 'private',
  });
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/coupons/${id}`, config)
        .then((res) => {
          setUpdatedCoupon(res.data);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          console.log(err);
        });
    }
  }, []);

  async function addPromotion() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const isPrivate = updatedCoupon.type === 'private';

    BaseUrl.post(
      `admin/coupons/`,
      {
        discount_percent: updatedCoupon.discount_percent,
        title: updatedCoupon.title,
        code: updatedCoupon.code,
        status: updatedCoupon.status,
        is_private: isPrivate,
      },
      config
    )
      .then((res) => {
        navHandler(`/coupons`);
        toast.success('Created Succesfully');
      })
      .catch((err) => {
        if (err.response.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsUpdating(false);
  }

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Coupons" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Coupon
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Coupon</p>
              <span>›</span>
              <span
                onClick={() => navHandler('/coupons')}
                className="text-blue-600 cursor-pointer"
              >
                Coupons
              </span>
              <span>›</span>
              <p>create</p>
            </nav>
          </div>
        </header>
        <main className="flex justify-center items-center p-8 w-full bg-[#F4F6F9]">
          <div className="w-full max-w-3xl flex flex-col gap-4 bg-white rounded-xl shadow-xl p-8 mt-4">
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Title :</span>
              <input
                onChange={(e) =>
                  setUpdatedCoupon((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg"
                value={updatedCoupon.title}
                placeholder="Enter the coupon name"
              />
            </pre>
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Discount Percentage :</span>
              <input
                type="number"
                onChange={(e) =>
                  setUpdatedCoupon((prev) => ({
                    ...prev,
                    discount_percent: parseFloat(e.target.value),
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg"
                value={updatedCoupon.discount_percent}
              />
            </pre>
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Code :</span>
              <input
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
                    setUpdatedCoupon((prev) => ({
                      ...prev,
                      code: inputValue,
                    }));
                  } else {
                    toast.error('Only alphanumeric characters are allowed.');
                  }
                }}
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg"
                value={updatedCoupon.code}
                placeholder="Enter an alphanumeric coupon code"
              />
            </pre>

            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Status :</span>
              <div className="flex items-center">
                <input
                  onChange={(e) =>
                    setUpdatedCoupon((prev) => ({
                      ...prev,
                      status: e.target.checked,
                    }))
                  }
                  type="checkbox"
                  checked={updatedCoupon.status}
                />
              </div>
            </pre>

            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Type :</span>
              <select
                onChange={(e) =>
                  setUpdatedCoupon((prev) => ({
                    ...prev,
                    type: e.target.value,
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-8 rounded-lg"
                value={updatedCoupon.type}
              >
                <option value="private">Private</option>
                <option value="public">Public</option>
              </select>
            </pre>

            <div className="mt-4">
              {isUpdating
                ? updatedCoupon.title &&
                  updatedCoupon.discount_percent > 0 &&
                  updatedCoupon.code && (
                    <button className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50">
                      Creating
                    </button>
                  )
                : updatedCoupon.title &&
                  updatedCoupon.discount_percent > 0 && (
                    <button
                      onClick={() => {
                        setIsUpdating(true);
                        addPromotion();
                      }}
                      className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white"
                    >
                      Create
                    </button>
                  )}
            </div>
          </div>
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddCoupon;
