import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Employee {
  id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  role: string;
  answer_sheets_checked: number;
  doubts_solved: number;
}

interface EmployeeRowProps {
  role: string;
  user: Employee;
  isEven: boolean;
}

const EmployeeRow: React.FC<EmployeeRowProps> = ({ user, isEven, role }) => {
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const navHandler = useNavigate();

  return (
    <div
      onClick={() => navHandler(`/employee/${user.id}`)}
      className={`grid grid-cols-[1fr_2fr_1fr_1fr] gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto`}
    >
      <div className="p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 truncate">
        {user.phone_number || '-'}
      </div>
      <div className="p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 truncate">
        {`${user.first_name} ${user.last_name}` || '-'}
      </div>
      <div className="p-4 text-base leading-6 whitespace-nowrap border-t border-solid border-zinc-200 text-neutral-800 truncate">
        {user.role || '-'}
      </div>
      {role === 'teacher' && (
        <div className="p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 truncate">
          {user.answer_sheets_checked || 0}
        </div>
      )}
      {role === 'doubt_solver' && (
        <div className="p-4 text-base leading-6 border-t border-solid border-zinc-200 text-neutral-800 truncate">
          {user.doubts_solved || 0}
        </div>
      )}
    </div>
  );
};

export default EmployeeRow;
