import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import DoubtRow from './DoubtRow';

const DoubtsTable: React.FC<{ isSolved: boolean }> = ({ isSolved }) => {
  const doubts = useAppSelector((state) => state.doubt.results) || [];

  const filteredDoubts = doubts.filter(
    (doubt) => doubt.doubt_status === isSolved
  );

  if (filteredDoubts.length === 0) {
    return <p className="text-center">No doubts found</p>;
  }

  return (
    <section className="overflow-x-auto">
      <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
        <div className="grid grid-cols-[1fr_1fr_1fr_1fr] text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="p-4">Submission</p>
          <p className="p-4">User</p>
          <p className="p-4">Question</p>
          <p className="p-4">Status</p>
        </div>

        {filteredDoubts.map((doubt, index) => (
          <DoubtRow key={index} doubt={doubt} isEven={index % 2 === 0} />
        ))}
      </div>
    </section>
  );
};

export default DoubtsTable;
