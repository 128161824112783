import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import {
  setCurrentTestSeriesPage,
  setTestSeries,
} from '../../redux/slices/test-series';
import TestSeriesTable from './TestSeriesTable';
import Loader from '../Loader'; // Assume you have a Loader component

function TestSeries() {
  interface TestTypes {
    id: string;
    title: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.testSeries.previous);
  const next = useAppSelector((state: RootState) => state.testSeries.next);
  const current = useAppSelector(
    (state: RootState) => state.testSeries.current
  );
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState('');
  const [debouncedTitle, setDebouncedTitle] = useState(title);
  const [testSeriesType, setTestSeriesType] = useState('');
  const [testSeriesTypes, setTestSeriesTypes] = useState<TestTypes[]>([]);
  const [courseType, setCourseType] = useState('');
  const [courseTypes, setCourseTypes] = useState<TestTypes[]>([]);
  const [loading, setLoading] = useState(false); // Loader state
  const [isFiltering, setIsFiltering] = useState(false);

  const fetchTestSeries = () => {
    if (!token) {
      navHandler('/login');
      return;
    }

    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const params = new URLSearchParams();

    if (isFiltering) {
      dispatch(setCurrentTestSeriesPage(1));
      params.append('page', '1');
    } else {
      params.append('page', String(current));
    }

    if (testSeriesType) params.append('test_series_type', testSeriesType);
    if (courseType) params.append('course_type', courseType);
    if (debouncedTitle) params.append('title', debouncedTitle);

    Promise.all([
      BaseUrl.get(`filter-test-series/?${params.toString()}`, config),
      BaseUrl.get(`admin/non-paginated-test-series-type`, config),
      BaseUrl.get(`admin/non-paginated-course-type/`, config),
    ])
      .then(([testSeriesRes, testSeriesTypesRes, courseTypesRes]) => {
        const modifiedData = {
          ...testSeriesRes.data,
          current: testSeriesRes.data.current,
        };
        console.log(modifiedData);
        dispatch(setTestSeries(modifiedData));
        setTestSeriesTypes(testSeriesTypesRes.data);
        setCourseTypes(courseTypesRes.data);
      })
      .catch((err) => {
        if (err.response?.status === 403) dispatch(setAccess(''));
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedTitle(title);
      setIsFiltering(true);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [title, testSeriesType, courseType]);

  useEffect(() => {
    if (isFiltering) {
      fetchTestSeries();
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      fetchTestSeries();
    }
  }, [current]);

  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    }
  }, [isFiltering]);

  const clearFilters = () => {
    setTitle('');
    setTestSeriesType('');
    setCourseType('');
    setIsFiltering(false);
    fetchTestSeries();
  };

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Test series" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Test Series
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Testseries</p>
              <span>›</span>
              <span>Test series</span>
            </nav>
          </div>
          <button
            onClick={() => navHandler('create')}
            className="flex gap-2.5 items-center justify-center p-4 text-base text-center text-white bg-green-600 rounded"
          >
            <img src="/addOrder.svg" />
            <span className="leading-[150%]">Add Test Series</span>
          </button>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-2xl font-semibold text-gray-700">Filters</p>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Title:
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="testSeriesType"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Test Series Type:
                </label>
                <select
                  id="testSeriesType"
                  name="testSeriesType"
                  value={testSeriesType}
                  onChange={(e) => setTestSeriesType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {testSeriesTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label
                  htmlFor="courseType"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Course Type:
                </label>
                <select
                  id="courseType"
                  name="courseType"
                  value={courseType}
                  onChange={(e) => setCourseType(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  {courseTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="relative">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                <Loader />
              </div>
            )}
            <TestSeriesTable />
          </div>
        </main>

        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrentTestSeriesPage(current - 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'<'}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrentTestSeriesPage(current + 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'>'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestSeries;
