import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import CouponRow from './CouponRow';

const CouponsTable: React.FC = () => {
  const coupons = useAppSelector((state) => state.coupon.results) || [];
  return (
    <section>
      <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
        <div className="grid grid-cols-4 text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="col-span-2 p-4">Coupon</p>
          <p className="p-4">Code</p>
          <p className="p-4">Status</p>
        </div>
        {coupons.map((coupon, index) => (
          <CouponRow key={index} coupon={coupon} isEven={index % 2 === 0} />
        ))}
      </div>
    </section>
  );
};

export default CouponsTable;
