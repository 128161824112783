export function generateFilenameWithTimestamp(filename : string) {
    // Create a timestamp in the format _DD-MM-YY-HH-MM-SS
    const currentDate = new Date();
    const formattedDate = `_${String(currentDate.getDate()).padStart(2, '0')}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getFullYear()).slice(-2)}-${String(currentDate.getHours()).padStart(2, '0')}-${String(currentDate.getMinutes()).padStart(2, '0')}-${String(currentDate.getSeconds()).padStart(2, '0')}`;

    // Extract the file extension if any
    const fileNameParts = filename.split('.');
    const extension = fileNameParts.length > 1 ? '.' + fileNameParts.pop() : '';
    const baseFileName = fileNameParts.join('.');

    // Append the formatted timestamp to the filename
    const fileNameWithTimestamp = baseFileName + formattedDate + extension;
    return fileNameWithTimestamp
}