import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Attempt {
  id: number;
  month: string;
  year: number;
}

interface Subject {
  id: number;
  title: string;
  description: string;
  course_type: number;
  attempts: Attempt;
}

interface TestSeriesType {
  id: number;
  title: string;
  description: string;
  attempts?: Attempt;
}

interface CourseType {
  id: number;
  title: string;
  description: string;
  subjects: Subject[];
  attempts: Attempt;
}

interface TestSeries {
  id: number;
  title: string;
  description: string;
  test_series_type: TestSeriesType;
  course_type: CourseType;
  discounted_price: number;
  mrp_price: number;
  status: boolean;
  created_at: string; // ISO Date string
  test_series_attempts: Attempt;
}

interface Test {
  id: number;
  test_title: string;
  time_duration: number;
  total_marks: number;
  valid_from: string; // ISO Date string
  valid_to: string; // ISO Date string
  question_paper: string; // URL to question paper
  answer_key: string; // URL to answer key
  subject: Subject;
  test_series: TestSeries;
}

interface TestRowProps {
  test: Test;
  isEven: boolean;
}

const TestRow: React.FC<TestRowProps> = ({ test, isEven }) => {
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const navHandler = useNavigate();

  const convertToBackendFormat = (localDateTime: string) => {
    const date = new Date(localDateTime);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`; // Return only the date part
  };

  return (
    <div
      onClick={() => navHandler(`/tests/${test.id}`)}
      className={`grid grid-cols-12 gap-0 justify-center ${rowClass} text-left cursor-pointer`}
    >
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.id}
      </div>

      {/* Ensure the test title is fully visible without overlapping */}
      <div className="col-span-2 p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.test_title}
      </div>

      {/* Rest of the columns */}
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.test_series.test_series_attempts.year}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.test_series.course_type.title}
      </div>
      <div className="justify-center col-span-2 items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.subject.title}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.test_series.test_series_type.title}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {convertToBackendFormat(test.valid_from)}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {convertToBackendFormat(test.valid_to)}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.time_duration}
      </div>
      <div className="justify-center items-start p-4 text-base border-t border-solid border-zinc-200 max-md:pr-5 whitespace-normal break-words overflow-auto">
        {test.total_marks}
      </div>
    </div>
  );
};

export default TestRow;
