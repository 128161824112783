import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { generateFilenameWithTimestamp } from '../../utils/CreateFileName';
import { PresignedUrlBaseLength } from '../../PresignedUrlBaseLength';

function AddPromotion() {
  interface promotionType {
    id: string;
    title: string;
    web_image_url: string;
    mobile_image_url: string;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const [promotion, setPromotion] = useState<promotionType>({
    id: '',
    title: '',
    web_image_url: '',
    mobile_image_url: '',
  });
  const [updatedPromotion, setUpdatedPromotion] = useState<promotionType>({
    id: '',
    title: '',
    web_image_url: '',
    mobile_image_url: '',
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedMobileFile, setSelectedMobileFile] = useState<File>();
  const [previewUrl, setPreviewUrl] = useState<string | null>(null); // To store the image preview URL
  const [previewMobUrl, setPreviewMobUrl] = useState<string | null>(null); // To store the image preview URL
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/promotions`, config)
        .then((res) => {
          setPromotion(res.data);
          setUpdatedPromotion(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403) dispatch(setAccess(''));
          console.log(err);
        });
    }
  }, [token, dispatch, navHandler]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result as string); // Set the preview URL
      };
      reader.readAsDataURL(file);
    }
  };

  async function updatePromotion() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let banner = '';
    let mobBanner = '';
    if (selectedFile) {
      const response = await BaseUrl.get(
        `promotions-presigned-url/?object_key=${generateFilenameWithTimestamp(selectedFile.name)}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(PresignedUrlBaseLength, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        banner = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }

    if (selectedMobileFile) {
      const response = await BaseUrl.get(
        `promotions-presigned-url/?object_key=${generateFilenameWithTimestamp(selectedMobileFile.name)}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedMobileFile, {
        headers: {
          'Content-Type': selectedMobileFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(PresignedUrlBaseLength, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        mobBanner = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    BaseUrl.post(
      `admin/promotions/`,
      {
        web_image_url: banner || updatedPromotion.web_image_url,
        mobile_image_url: mobBanner || updatedPromotion.mobile_image_url,
        title: updatedPromotion.title,
      },
      config
    )
      .then((res) => {
        navHandler(`/promotion`);
        toast.success('Created Successfully');
      })
      .catch((err) => {
        if (err.response.status === 403) dispatch(setAccess(''));
        console.log(err);
      });

    setIsUpdating(false);
  }

  const handleAttachClick = () => {
    const inpElement = document.getElementById('banner') as HTMLElement;
    inpElement?.click();
  };

  const handleMobAttachClick = () => {
    const inpElement = document.getElementById('mobBanner');
    inpElement?.click();
  };

  const handleMobileFileChange = (event: any) => {
    // Handle the file selection
    const file = event.target.files[0];
    setSelectedMobileFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewMobUrl(reader.result as string); // Set the preview URL
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Promotions" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Promotion
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Promotion</p>
              <span>›</span>
              <span
                onClick={() => navHandler('/promotions')}
                className="text-blue-600 cursor-pointer"
              >
                Promotions
              </span>
              <span>›</span>
              <p className="">create</p>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-col gap-4 p-4 mt-4 bg-white rounded-xl shadow-xl max-md:px-5">
            <div className="flex flex-col gap-4 w-full">
              <div className="flex items-center justify-between">
                <span className="font-extrabold">Title:</span>
                <input
                  onChange={(e) =>
                    setUpdatedPromotion((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                  className="outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg flex-1 ml-4"
                  value={updatedPromotion.title}
                />
              </div>

              <div className="flex items-center">
                <span className="font-extrabold">Banner:</span>
                {previewUrl && (
                  <img
                    src={previewUrl}
                    alt={promotion.title}
                    className="ml-4 py-2 w-full max-w-xs md:max-w-md rounded-lg"
                  />
                )}
              </div>

              <div className="flex flex-col gap-3 w-full">
                <span className="font-extrabold">
                  Upload new banner for web application:
                </span>
                <div className="space-y-2 w-full p-4 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100">
                  <img
                    className="mx-auto font-semibold text-sm"
                    src="/upload.svg"
                    alt="upload"
                  />
                  <p className="text-center font-semibold text-sm">Upload</p>
                  <div className="flex justify-center">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="banner"
                      onChange={handleFileChange}
                    />
                    <button
                      onClick={handleAttachClick}
                      className="p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg"
                    >
                      {updatedPromotion.web_image_url || selectedFile
                        ? 'Replace'
                        : 'Browse'}
                    </button>
                  </div>
                  <p className="overflow-auto text-center">
                    {selectedFile?.name ||
                      updatedPromotion.web_image_url?.substring(72)}
                  </p>
                </div>
              </div>

              <div className="flex items-center">
                <span className="font-extrabold">Mobile Banner:</span>
                <img
                  src={previewMobUrl || ''}
                  alt={promotion.title}
                  className="ml-4 py-2 w-full max-w-xs md:max-w-md rounded-lg"
                />
              </div>

              <div className="flex flex-col gap-3 w-full">
                <span className="font-extrabold">
                  Upload new banner for mobile application:
                </span>
                <div className="space-y-2 w-full p-4 border border-dashed border-2 border-gray-400 rounded-lg bg-gray-100">
                  <img
                    className="mx-auto font-semibold text-sm"
                    src="/upload.svg"
                    alt="upload"
                  />
                  <p className="text-center font-semibold text-sm">Upload</p>
                  <div className="flex justify-center">
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      id="mobBanner"
                      onChange={handleMobileFileChange}
                    />
                    <button
                      onClick={handleMobAttachClick}
                      className="p-2 px-4 cursor-pointer text-white bg-green-400 rounded-lg"
                    >
                      {updatedPromotion.mobile_image_url || selectedMobileFile
                        ? 'Replace'
                        : 'Browse'}
                    </button>
                  </div>
                  <p className="overflow-auto text-center">
                    {selectedMobileFile?.name ||
                      updatedPromotion.mobile_image_url?.substring(72)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {isUpdating
            ? (JSON.stringify(updatedPromotion) !== JSON.stringify(promotion) ||
                selectedFile) && (
                <button className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50">
                  Adding
                </button>
              )
            : (JSON.stringify(updatedPromotion) !== JSON.stringify(promotion) ||
                selectedFile) && (
                <button
                  onClick={() => {
                    setIsUpdating(true);
                    updatePromotion();
                  }}
                  className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white"
                >
                  Add
                </button>
              )}
        </main>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddPromotion;
