import React, { useEffect, useState, useRef } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { setCurrentUserPage, setUsers } from '../../redux/slices/users';
import UsersTable from './UsersTable';
import Loader from '../Loader';

interface User {
  id: string;
  name: string;
  phone: string;
  email: string;
  created_at: Date;
}

function Users() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.users.previous);
  const next = useAppSelector((state: RootState) => state.users.next);
  const current = useAppSelector((state: RootState) => state.users.current);
  const users = useAppSelector((state) => state.users.results) || [];
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>();
  const [isFiltering, setIsFiltering] = useState(false);
  const isMounted = useRef(false);

  const fetchUsers = () => {
    if (!token) {
      navHandler('/login');
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let params = new URLSearchParams();

    if (isFiltering) {
      params.append('page', '1');
    } else {
      params.append('page', String(current));
    }

    if (phone) params.append('phone', phone);
    if (name) params.append('name', name);
    if (email) params.append('email', email);

    setLoading(true);

    BaseUrl.get(`filter-user/?${params.toString()}`, config)
      .then((res) => {
        const modifiedData = {
          ...res.data,
          current: res.data.current,
        };
        dispatch(setUsers(modifiedData));
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          dispatch(setAccess(''));
        }
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInputChange = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setDebounceTimeout(
      window.setTimeout(() => {
        setIsFiltering(true);
        dispatch(setCurrentUserPage(1));
      }, 300)
    );
  };

  useEffect(() => {
    if (isMounted.current) {
      handleInputChange();
    } else {
      isMounted.current = true;
    }
  }, [name, phone, email]);

  // Effect to trigger fetch based on isFiltering
  useEffect(() => {
    if (isFiltering) {
      fetchUsers();
    }
  }, [isFiltering]);

  // Effect to handle page changes (only if not filtering)
  useEffect(() => {
    if (!isFiltering) {
      fetchUsers();
    }
  }, [current]);

  // Reset filtering state after fetching
  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false);
    }
  }, [isFiltering]);

  const clearFilters = () => {
    setName('');
    setPhone('');
    setEmail('');
    setIsFiltering(false);
    fetchUsers();
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Users" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row mx-auto sm:mx-0 gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Users
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Client</p>
              <span>›</span>
              <span>Users</span>
            </nav>
          </div>
        </header>

        {/* Filter Section */}
        <div className="my-6 px-8 max-md:px-5">
          <div className="flex justify-between items-center mb-4">
            <p className="text-lg font-semibold text-gray-700">Filters</p>
            <button
              onClick={clearFilters}
              className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600"
            >
              Clear All Filters
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-center">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Username"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Phone:
              </label>
              <input
                type="number"
                id="phone"
                name="phone"
                placeholder="Enter Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Email:
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          </div>
        </div>

        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
          <div className="relative">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                <Loader />
              </div>
            )}
            <UsersTable />
          </div>
        </main>
        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrentUserPage(current - 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'<'}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrentUserPage(current + 1))}
              className="px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 cursor-pointer"
            >
              {'>'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Users;
