import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SpecificUser() {
  interface subject {
    title: string;
  }
  interface purchase {
    test_series: {
      title: string;
    };
    subjects: subject[];
  }
  interface userType {
    phone: number;
    name: string;
    email: string;
    // gender: string;
    created_at: string;
    purchases: purchase[];
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [user, setUser] = useState<userType>({
    phone: 0,
    name: '',
    email: '',
    // gender: '',
    created_at: '',
    purchases: [],
  });

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/user/${id}`, config)
        .then((res) => {
          setUser(res.data);
          setEmail(res.data.email);
          setPhone(res.data.phone.toString());
        })
        .catch((err) => {
          if (err.response.status === 403) dispatch(setAccess(''));
          if (err.response.status === 404) setStatus(false);
          console.log(err);
        });
    }
  }, [id, token, dispatch, navHandler]);

  const validatePhoneNumber = (value: string) => {
    const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
    return phonePattern.test(value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhone(value);

    if (validatePhoneNumber(value)) {
      setError('');
    } else {
      setError('Please enter a valid 10-digit phone number');
    }
  };

  function updateUser() {
    if (error) {
      toast.error('Please fix the errors before updating');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.put(
      `admin/user/${id}/`,
      {
        email: email,
        phone: parseInt(phone),
      },
      config
    )
      .then((res) => {
        setUser(res.data);
        toast.success('Updated Successfully');
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
          if (err.response.status === 403) {
            dispatch(setAccess(''));
          }
        } else {
          console.log('Unknown error:', err);
        }
      });
  }

  const toSentenceCase = (str: String) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Users" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col sm:flex-row mx-auto sm:mx-0 gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center smjustify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Users
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/users" className="text-blue-600">
                Client
              </a>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/users')}
              >
                Users
              </span>
              <span>›</span>
              {status ? <span>{user.phone}</span> : <span>Not Found</span>}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="flex justify-evenly gap-5 items-start p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-lg max-md:px-5 w-fit">
              {/* User Details Section */}
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                User Details
              </h2>

              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-semibold text-gray-700">Name:</span>
                <p className="col-span-3 py-2 px-8 text-gray-600">
                  {user.name || '-'}
                </p>
              </pre>

              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-semibold text-gray-700">Email:</span>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  className="col-span-3 outline-none border border-zinc-300 py-2 px-8 rounded-lg focus:ring-2 focus:ring-blue-400"
                  value={email}
                />
              </pre>

              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-semibold text-gray-700">Phone:</span>
                <input
                  onChange={handlePhoneChange}
                  className={`col-span-3 outline-none border border-zinc-300 py-2 px-8 rounded-lg focus:ring-2 focus:ring-blue-400 ${
                    error ? 'border-red-500' : ''
                  }`}
                  value={phone}
                />
              </pre>
              {error && <p className="col-span-3 text-red-500">{error}</p>}

              <pre className="w-full grid grid-cols-4 items-center">
                <span className="font-semibold text-gray-700">Created at:</span>
                <p className="col-span-3 py-2 px-8 text-gray-600">
                  {user.created_at || '-'}
                </p>
              </pre>

              {(user.phone.toString() !== phone || user.email !== email) && (
                <button
                  onClick={updateUser}
                  className="mt-4 p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white hover:bg-blue-500 transition"
                >
                  Update
                </button>
              )}
            </div>

            {/* Purchases Section */}
            {user.purchases.length > 0 ? (
              <div className="flex flex-col gap-6 p-8 mt-4 bg-white rounded-xl shadow-lg w-fit max-md:px-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Purchases
                </h2>
                <ul className="space-y-6">
                  {user.purchases.map((purchase, index) => (
                    <li
                      key={index}
                      className="p-4 border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition"
                    >
                      <span className="text-xl font-semibold text-gray-700">
                        Test Series:{' '}
                      </span>
                      <span className="text-lg font-medium text-gray-600 mt-1">
                        {purchase.test_series.title}
                      </span>

                      <h4 className="text-lg font-semibold text-gray-700 mt-4">
                        Subjects
                      </h4>
                      <ul className="mt-2 pl-4 list-disc list-inside text-gray-600">
                        {purchase.subjects.map((subject, subIndex) => (
                          <li key={subIndex} className="py-1">
                            {toSentenceCase(subject.title)}
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-lg max-md:px-5 w-fit">
                <p className="text-lg font-semibold text-gray-600">
                  No Purchases
                </p>
              </div>
            )}
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold">
            No User Found With Id : {id}
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
}

export default SpecificUser;
