import React from 'react';
import OrderRow from './OrderRow';
import { useAppSelector } from '../../redux/hooks/hooks';

const OrdersTable = () => {
  const orders = useAppSelector((state) => state.orders.results) || [];

  if (orders.length === 0) {
    return <p className="text-center">No orders found</p>;
  }

  return (
    <section>
      <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
        <div className="grid grid-cols-[1fr_1fr_1fr_1fr] text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="p-4">User</p>
          <p className="p-4">Order Value</p>
          <p className="p-4">Order Status</p>
          <p className="p-4">Created At</p>
        </div>

        {orders.map((order, index) => (
          <OrderRow key={index} order={order} isEven={index % 2 === 0} />
        ))}
      </div>
    </section>
  );
};

export default OrdersTable;
