import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import SubmissionRow from './SubmissionRow';
import { RootState } from '../../redux/store';

interface submissionProp {
  assigned: boolean;
  setTeacherChange: React.Dispatch<React.SetStateAction<boolean>>;
  teacherChange: boolean;
}

interface Teacher {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
}

interface Submission {
  id: number;
  test: {
    test_title: string;
    subject: {
      id: number;
    };
  };
  user: {
    name: string;
  };
  assigned_teacher: Teacher | null;
  available_teachers: Teacher[];
  check_status: boolean;
}

const SubmissionsTable: React.FC<submissionProp> = ({
  assigned,
  teacherChange,
  setTeacherChange,
}) => {
  const submissions = useAppSelector((state) => state.submission.results) || [];
  const role = useAppSelector((state: RootState) => state.auth.role);

  // const sortedSubmissions = [...submissions].sort(
  //   (a, b) =>
  //     new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  // );

  return (
    <section>
      <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
        <div className="grid grid-cols-11 text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="col-span-2 p-4 truncate">Test</p>
          <p className="col-span-2 p-4 truncate">Subject</p>
          <p className="col-span-2 p-4 truncate">User</p>
          {role === 'admin' && <p className="col-span-2 p-4 truncate">Assigned Teacher</p>}
          <p className="p-4 truncate">Check Status</p>
          <p className="col-span-2 p-4 truncate">Submitted at</p>
        </div>

        {assigned &&
          submissions.map(
            (submission, index) =>
              submission.assigned_teacher && (
                <SubmissionRow
                  teacherChange={teacherChange}
                  setTeacherChange={setTeacherChange}
                  key={submission.id}
                  submission={submission}
                  isEven={index % 2 === 0}
                />
              )
          )}

        {!assigned &&
          submissions.map(
            (submission, index) =>
              !submission.assigned_teacher && (
                <SubmissionRow
                  teacherChange={teacherChange}
                  setTeacherChange={setTeacherChange}
                  key={submission.id}
                  submission={submission}
                  isEven={index % 2 === 0}
                />
              )
          )}
      </div>
    </section>
  );
};

export default SubmissionsTable;
