import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import CouponsTable from './CouponsTable';
import { setCurrentCouponPage, setCoupons } from '../../redux/slices/coupons';
import Loader from '../Loader'; // Import the Loader component

function Coupons() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.coupon.previous);
  const next = useAppSelector((state: RootState) => state.coupon.next);
  const current = useAppSelector((state: RootState) => state.coupon.current);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      setIsLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/coupons/?page=${current}`, config)
        .then((res) => {
          const data = {
            ...res.data,
            results: res.data.results || res.data, 
            current: res.data.current || current, 
          };
          dispatch(setCoupons(data));
        })
        .catch((err) => {
          if (err.response.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false); 
        });
    }
  }, [token, current, dispatch, navHandler]);

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Coupons" />
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        {/* Header */}
        <header className="flex flex-col sm:flex-row gap-5 justify-between px-6 py-6 w-full border-b border-solid border-zinc-200">
          {/* Title and Breadcrumb */}
          <div className="flex flex-col sm:flex-row gap-5 mx-auto sm:mx-0 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Coupon
            </h1>
            <nav className="flex gap-2.5 items-center text-base leading-6 text-gray-500">
              <a href="/" className="text-blue-600">
                Home
              </a>
              <span>›</span>
              <p className="text-blue-600">Coupon</p>
              <span>›</span>
              <span>Coupons</span>
            </nav>
          </div>

          {/* Add Coupon Button */}
          <div className="flex justify-center md:justify-end mt-4 md:mt-0">
            <button
              onClick={() => navHandler('create')}
              className="flex gap-2 items-center justify-center px-5 py-3 text-base text-white bg-green-600 rounded-lg hover:bg-green-700 transition duration-300 ease-in-out"
            >
              <img
                src="/addOrder.svg"
                alt="Add Coupon Icon"
                className="w-5 h-5"
              />
              <span>Add Coupon</span>
            </button>
          </div>
        </header>

        {/* Main Content */}
        <main className="flex flex-col p-8 w-full max-md:px-5 max-md:mt-10">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader />
            </div>
          ) : (
            <CouponsTable />
          )}
        </main>

        {/* Pagination */}
        {!isLoading && (
          <div className="flex gap-2 w-full justify-center items-center my-4">
            {prev && (
              <button
                onClick={() => dispatch(setCurrentCouponPage(current - 1))}
                className={`px-3 py-2 rounded-lg border border-gray-400 text-xl font-semibold bg-gray-200 hover:bg-gray-300 cursor-pointer transition ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
                {'<'}
              </button>
            )}
            <div className="px-3 py-2 rounded-lg border border-blue-400 text-xl font-semibold text-white bg-blue-600 cursor-pointer">
              {current}
            </div>
            {next && (
              <button
                onClick={() => dispatch(setCurrentCouponPage(current + 1))}
                className={`px-3 py-2 rounded-lg border border-gray-400 text-xl font-semibold bg-gray-200 hover:bg-gray-300 cursor-pointer transition ${
                  isLoading ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isLoading}
              >
                {'>'}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Coupons;
