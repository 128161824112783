import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import EmployeeRow from './EmployeeRow';

interface EmployeesTableProps {
  role: string;
}

const EmployeesTable: React.FC<EmployeesTableProps> = ({ role }) => {
  const users = useAppSelector((state) => state.employee.results) || [];

  if (users.length === 0) {
    return <p className="text-center">No users found</p>;
  }

  return (
    <section>
      <div className="flex flex-col justify-center bg-white rounded shadow w-full">
        <div className="grid grid-cols-[1fr_2fr_1fr_1fr] text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="p-4">Phone</p>
          <p className="p-4">Name</p>
          <p className="p-4">Role</p>
          {role === 'teacher' && <p className="p-4">Answer Sheets</p>}
          {role === 'doubt_solver' && <p className="p-4">Doubts Solved</p>}
        </div>
        {users.map((user, index) => (
          <EmployeeRow
            role={role}
            key={index}
            user={user}
            isEven={index % 2 === 0}
          />
        ))}
      </div>
    </section>
  );
};

export default EmployeesTable;
