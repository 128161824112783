import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateEmployee() {
  interface employeeType {
    password: string;
    phone_number: string;
    first_name: string;
    last_name: string;
    role: string;
    assigned_subject_id?: number;
  }

  interface CourseTypes {
    id: number;
    title: string;
  }

  interface SubjectTypes {
    id: string;
    title: string;
    course_type: number;
  }

  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();

  const [newEmployee, setNewEmployee] = useState<employeeType>({
    password: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    role: 'teacher',
    assigned_subject_id: 0,
  });

  const roles = ['admin', 'teacher', 'doubt_solver'];
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [courseTypes, setCourseTypes] = useState<CourseTypes[]>([]);
  const [subjects, setSubjects] = useState<SubjectTypes[]>([]);
  const [filteredSubjects, setFilteredSubjects] = useState<SubjectTypes[]>([]);
  const [selectedCourseType, setSelectedCourseType] = useState<CourseTypes>();

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      // Fetch course types
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseTypes(res.data);
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        });
      // Fetch Subjects
      BaseUrl.get(`admin/non-paginated-subject`, config) // Update with your subjects API
        .then((res) => {
          setSubjects(res.data);
        })
        .catch((err) => {
          if (err.response?.status === 403) {
            dispatch(setAccess(''));
          }
          console.log(err);
        });
    }
  }, [token, navHandler, dispatch]);

  const validatePhoneNumber = (value: string) => {
    const phonePattern = /^[0-9]{10}$/; // Matches a 10-digit number
    return phonePattern.test(value);
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewEmployee((prev) => ({ ...prev, phone_number: value }));

    if (validatePhoneNumber(value)) {
      setError('');
    } else {
      setError('Please enter a valid 10-digit phone number');
    }
  };

  function createEmployee() {
    if (error) {
      toast.error('Please fix the errors before creating');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      phone_number: newEmployee.phone_number,
      first_name: newEmployee.first_name,
      last_name: newEmployee.last_name,
      role: newEmployee.role,
      password: newEmployee.password,
    };

    BaseUrl.post(`admin/employee/`, payload, config)
      .then((res) => {
        navHandler(`/employees`);
        toast.success('Created Successfully');
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(setAccess(''));
        }
        console.log(err);
      });
  }

  function createTeacher() {
    if (error) {
      toast.error('Please fix the errors before creating');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      first_name: newEmployee.first_name,
      last_name: newEmployee.last_name,
      phone_number: newEmployee.phone_number,
      password: newEmployee.password,
      assigned_subject_id: newEmployee.assigned_subject_id,
    };

    BaseUrl.post(`admin/teacher/`, payload, config)
      .then((res) => {
        navHandler(`/employees`);
        toast.success('Teacher Created Successfully');
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(setAccess(''));
        }
        console.log(err);
      });
  }

  function createDoubtSolver() {
    if (error) {
      toast.error('Please fix the errors before creating');
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const payload = {
      phone_number: newEmployee.phone_number,
      first_name: newEmployee.first_name,
      last_name: newEmployee.last_name,
      password: newEmployee.password,
    };

    BaseUrl.post(`admin/doubt-solver/`, payload, config)
      .then((res) => {
        navHandler(`/employees`);
        toast.success('Doubt Solver Created Successfully');
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(setAccess(''));
        }
        console.log(err);
      });
  }

  useEffect(() => {
    // Filter subjects based on selected course type
    console.log(selectedCourseType?.id);
    if (selectedCourseType) {
      const filtered = subjects.filter(
        (subject) => subject.course_type === selectedCourseType.id
      );
      setFilteredSubjects(filtered);

      // Auto-select the first available subject if there are any filtered subjects
      if (filtered.length > 0) {
        const selectedSubjectId = filtered[0].id; // This should be a number
        setNewEmployee((prev) => ({
          ...prev,
          assigned_subject_id: parseInt(selectedSubjectId), // Ensure this is a number
        }));
      } else {
        setNewEmployee((prev) => ({
          ...prev,
          assigned_subject_id: 0, // Reset assigned_subject_id
        }));
      }
    } else {
      setFilteredSubjects(subjects); // Reset if no course type is selected
      setNewEmployee((prev) => ({ ...prev, assigned_subject_id: 0 })); // Reset assigned_subject_id
    }
  }, [selectedCourseType, subjects]);

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Employees" />
      <div className="col-span-1 md:col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-5 py-6 border-b border-solid border-zinc-200 flex-wrap">
          <div className="flex flex-col sm:flex-row gap-5 my-auto whitespace-nowrap">
            <h1 className="text-center sm:py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Employees
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/users" className="text-blue-600">
                Employees
              </a>
              <span>›</span>
              <span
                className="text-blue-600 cursor-pointer"
                onClick={() => navHandler('/employees')}
              >
                Employee
              </span>
              <span>›</span>
              <span>Create</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-4 md:p-8 w-full mt-4">
          <div className="flex flex-col gap-4 p-4 bg-white rounded-xl shadow-xl w-full mx-auto max-w-4xl">
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Phone : </span>
              <input
                type="number"
                name="phone_number"
                onChange={handlePhoneChange}
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                value={newEmployee.phone_number}
                autoComplete="off"
              />
            </pre>
            {error && <p className="col-span-2 text-red-500">{error}</p>}
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">First Name : </span>
              <input
                name="first_name"
                onChange={(e) =>
                  setNewEmployee((prev) => ({
                    ...prev,
                    first_name: e.target.value,
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                value={newEmployee.first_name}
                autoComplete="given-name"
              />
            </pre>
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Last Name : </span>
              <input
                name="last_name"
                onChange={(e) =>
                  setNewEmployee((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                value={newEmployee.last_name}
                autoComplete="family-name"
              />
            </pre>
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Password : </span>
              <input
                type="password"
                name="password"
                onChange={(e) =>
                  setNewEmployee((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                value={newEmployee.password}
                autoComplete="new-password"
              />
            </pre>
            <div>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                <span className="font-extrabold">Confirm Password : </span>
                <input
                  type="password"
                  name="confirm_password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="col-span-2 outline-none border border-1 border-zinc-300 py-2 px-4 rounded-lg"
                  value={confirmPassword}
                  autoComplete="new-password"
                />
              </pre>
              <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
                {confirmPassword &&
                  confirmPassword !== newEmployee.password && (
                    <p className="col-start-2 mt-2 text-red-400 text-sm font-semibold">
                      Password doesn't match
                    </p>
                  )}
              </pre>
            </div>
            <pre className="w-full grid grid-cols-1 md:grid-cols-3 items-center">
              <span className="font-extrabold">Role : </span>
              <select
                onChange={(e) => {
                  setNewEmployee((prev) => ({
                    ...prev,
                    role: e.target.value,
                  }));
                }}
                className="col-span-2 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                value={newEmployee.role}
              >
                {roles.map((role, index) => (
                  <option className="w-full" key={index} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </pre>

            {newEmployee.first_name &&
              newEmployee.phone_number &&
              newEmployee.role &&
              newEmployee.password &&
              newEmployee.password === confirmPassword && (
                <>
                  {newEmployee.role === 'teacher' && (
                    <button
                      onClick={createTeacher}
                      className="p-2 w-1/4 mx-auto rounded-lg bg-blue-600 text-lg font-semibold text-white mt-4"
                    >
                      Create Teacher
                    </button>
                  )}
                  {newEmployee.role === 'doubt_solver' && (
                    <button
                      onClick={createDoubtSolver}
                      className="p-2 w-1/4 mx-auto rounded-lg bg-blue-600 text-lg font-semibold text-white mt-4"
                    >
                      Create Doubt Solver
                    </button>
                  )}
                  {newEmployee.role !== 'teacher' &&
                    newEmployee.role !== 'doubt_solver' && (
                      <button
                        onClick={createEmployee}
                        className="p-2 w-1/4 mx-auto rounded-lg bg-blue-600 text-lg font-semibold text-white mt-4"
                      >
                        Create Admin
                      </button>
                    )}
                </>
              )}
          </div>
        </main>
        <ToastContainer />
      </div>
    </div>
  );
}

export default CreateEmployee;
