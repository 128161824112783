import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setAccess } from '../../redux/slices/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { generateFilenameWithTimestamp } from '../../utils/CreateFileName';
import { PresignedUrlBaseLength } from '../../PresignedUrlBaseLength';

function SpecificFees() {
  interface attemptInterface {
    id: number;
    month?: string;
    year?: string;
  }

  interface courseTypeInterface {
    id?: number;
    title?: string;
    attempts?: Array<attemptInterface>;
  }

  interface testSeriesTypeInterface {
    id?: number;
    title?: string;
  }

  interface feesType {
    id: number;
    attempts: attemptInterface[];
    course_type: courseTypeInterface;
    test_series_type: testSeriesTypeInterface;
    fees_structure: string;
    syllabus: string;
  }
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [status, setStatus] = useState<boolean>(true);
  const [fees, setFees] = useState<feesType>({
    id: 0,
    attempts: [],
    course_type: {},
    test_series_type: {},
    fees_structure: '',
    syllabus: '',
  });
  const [feesNew, setFeesNew] = useState<feesType>({
    id: 0,
    attempts: [],
    course_type: {},
    test_series_type: {},
    fees_structure: '',
    syllabus: '',
  });
  const [courseType, setCourseType] = useState<courseTypeInterface[]>([]);
  const [courseTypeId, setCourseTypeId] = useState<number>();
  const [testSeriesType, setTestSeriesType] = useState<courseTypeInterface[]>(
    []
  );
  const [testSeriesTypeId, setTestSeriesTypeId] = useState<number>();
  const [isListVisible, setIsListVisible] = useState(false);
  const [orgAttempts, setOrgAttempts] = useState<number[]>([]);
  const [selectedAttempts, setSelectedAttempts] = useState<number[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedSyllabus, setSelectedSyllabus] = useState<File>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  useEffect(() => {
    if (!token) {
      navHandler('/login');
    } else {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      BaseUrl.get(`admin/fee-structure-syllabus/${id}`, config)
        .then((res) => {
          setFees(res.data);
          setFeesNew(res.data);
          const attemptIds = res.data.attempts.map(
            (att: { id: number }) => att.id
          );
          setSelectedAttempts(attemptIds);
          setOrgAttempts(attemptIds);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          if (err.response.status == 404) setStatus(false);
          console.log(err);
        });
      BaseUrl.get(`admin/non-paginated-course-type/`, config)
        .then((res) => {
          setCourseType(res.data);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          if (err.response.status == 404) setStatus(false);
          console.log(err);
        });
      BaseUrl.get(`admin/non-paginated-test-series-type/`, config)
        .then((res) => {
          setTestSeriesType(res.data);
        })
        .catch((err) => {
          if (err.response.status == 403) dispatch(setAccess(''));
          if (err.response.status == 404) setStatus(false);
          console.log(err);
        });
    }
  }, []);

  const handleCheckboxChange = (
    event: { target: { checked: any } },
    attemptId: number
  ) => {
    if (event.target.checked) {
      setSelectedAttempts([...selectedAttempts, attemptId]);
    } else {
      setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
    }
  };

  const removeSelectedAttempt = (attemptId: number) => {
    setSelectedAttempts(selectedAttempts.filter((id) => id !== attemptId));
  };

  async function updateSubmission() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    let feesStructure = '';
    if (selectedFile) {
      const response = await BaseUrl.get(
        `fees-structure-presigned-url/?object_key=${generateFilenameWithTimestamp(selectedFile.name)}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(PresignedUrlBaseLength, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        feesStructure = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    let syllabus = '';
    if (selectedSyllabus) {
      const response = await BaseUrl.get(
        `syllabus-presigned-url/?object_key=${generateFilenameWithTimestamp(selectedSyllabus.name)}`,
        config
      );
      const presignedUrl = response.data.url;
      const uploadResponse = await axios.put(presignedUrl, selectedSyllabus, {
        headers: {
          'Content-Type': selectedSyllabus.type,
          'x-amz-acl': 'public-read',
        },
      });
      if (uploadResponse.status === 200) {
        const endIndex = presignedUrl.indexOf('?');
        const extractedString = presignedUrl.substring(PresignedUrlBaseLength, endIndex);
        const baseUrl = process.env.REACT_APP_DIGITAL_OCEAN;
        syllabus = baseUrl + extractedString;
      } else {
        alert('File upload failed');
      }
    }
    BaseUrl.put(
      `admin/fee-structure-syllabus/${id}/`,
      {
        attempts_ids: selectedAttempts,
        course_type_id: feesNew.course_type?.id,
        test_series_type_id: feesNew.test_series_type?.id,
        fees_structure: feesStructure || feesNew.fees_structure,
        syllabus: syllabus || feesNew.syllabus,
      },
      config
    )
      .then((res) => {
        setFees(res.data);
        setFeesNew(res.data);
        const attemptIds = res.data.attempts.map(
          (att: { id: number }) => att.id
        );
        setSelectedAttempts(attemptIds);
        setOrgAttempts(attemptIds);
        setSelectedFile(undefined);
        setSelectedSyllabus(undefined);
        toast.success('Updated Succesfully');
      })
      .catch((err) => {
        if (err.response) {
          console.log('Error status:', err.response.status);
          console.log('Error data:', err.response.data);
          if (err.response.status === 403) {
            dispatch(setAccess(''));
          }
        } else {
          console.log('Unknown error:', err);
        }
      });
    setIsUpdating(false);
  }

  const handleAttachClick = () => {
    const inpElement = document.getElementById('feesStructure');
    inpElement?.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleSyllabusClick = () => {
    const inpElement = document.getElementById('syllabus');
    inpElement?.click();
  };

  const handleSyllabusChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedSyllabus(file);
  };

  function deleteFeesStructure() {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    BaseUrl.delete(`admin/fee-structure-syllabus/${id}/`, config)
      .then((res) => {
        toast.success('Deleted Succesfully');
        navHandler('/fees-and-syllabus');
      })
      .catch((err) => {
        if (err.response.status == 403) dispatch(setAccess(''));
        console.log(err);
      });
    setIsModalOpen(false);
  }

  return (
    <div className="text-sm grid grid-cols-5">
      <Sidebar activeLabel="Fee structure and syllabus" />
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-8 w-11/12 max-w-md mx-auto">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">
              Are you sure you want to delete this Fees Structure and Syllabus?
            </h2>
            <div className="flex justify-end gap-4">
              <button
                onClick={deleteFeesStructure}
                className="px-6 py-2 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700 transition duration-200"
              >
                Yes
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-6 py-2 bg-gray-300 text-gray-800 font-semibold rounded-md hover:bg-gray-400 transition duration-200"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-11 py-6 w-full border-b border-solid border-zinc-200 max-md:flex-wrap max-md:px-5 max-md:max-w-full">
          <div className="flex gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="justify-center py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Fee structure and syllabus
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a className="text-blue-600">Test Series</a>
              <span>›</span>
              <span>Fee structure and syllabus</span>
              {/* {status ? <span>{order.user.phone}_{order.created_at}</span> : <span>Not Found</span>} */}
            </nav>
          </div>
        </header>
        {status ? (
          <main className="grid grid-cols-4 flex flex-col p-8 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
            <div className="col-span-3 flex flex-col gap-4 p-8 mt-4 bg-white rounded-xl shadow-xl max-md:px-5 w-11/12 mx-auto">
              {testSeriesType && (
                <pre className="w-full text-sm grid grid-cols-5 items-center">
                  <span className="font-extrabold">Test Series type : </span>
                  <select
                    onChange={(e) => {
                      setTestSeriesTypeId(parseInt(e.target.value));
                      const selectedTestSeriesTypeId = parseInt(e.target.value);
                      const selectedTestSeriesType = Object.values(
                        testSeriesType
                      ).find(
                        (testSeriesType) =>
                          testSeriesType?.id == selectedTestSeriesTypeId
                      );
                      setFeesNew((prev) => ({
                        ...prev,
                        test_series_type: selectedTestSeriesType || {},
                      }));
                    }}
                    className="col-span-4 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                  >
                    {Object.entries(testSeriesType).map(
                      ([key, testSeriesType]) =>
                        testSeriesType &&
                        testSeriesType?.id && (
                          <option
                            className="w-full"
                            key={key}
                            value={testSeriesType?.id}
                            selected={
                              testSeriesType?.id == feesNew.test_series_type?.id
                            }
                          >
                            {testSeriesType.title}
                          </option>
                        )
                    )}
                  </select>
                </pre>
              )}
              {courseType && (
                <pre className="w-full text-sm grid grid-cols-5 items-center">
                  <span className="font-extrabold">Course type : </span>
                  <select
                    onChange={(e) => {
                      setCourseTypeId(parseInt(e.target.value));
                      const selectedCourseId = parseInt(e.target.value);
                      const selectedCourse = Object.values(courseType).find(
                        (course) => course?.id == selectedCourseId
                      );
                      setFeesNew((prev) => ({
                        ...prev,
                        course_type: selectedCourse || {},
                      }));
                      setSelectedAttempts([]);
                    }}
                    className="col-span-4 w-1/2 outline-none border border-1 border-zinc-300 p-2 rounded-lg"
                  >
                    {Object.entries(courseType).map(
                      ([key, course]) =>
                        course &&
                        course?.id && (
                          <option
                            className="w-full"
                            key={key}
                            value={course?.id}
                            selected={course?.id == feesNew.course_type?.id}
                          >
                            {course.title}
                          </option>
                        )
                    )}
                  </select>
                </pre>
              )}

              <pre className="w-full text-sm grid grid-cols-4 items-center">
                <span className="font-extrabold col-span-1">
                  Selected Attempts:
                </span>
                <div className="grid grid-cols-4 gap-2 col-span-3">
                  {feesNew?.course_type?.attempts &&
                    feesNew?.course_type?.attempts
                      .filter((attempt) =>
                        selectedAttempts.includes(attempt?.id)
                      )
                      .map((attempt) => (
                        <div
                          key={attempt?.id}
                          className="mb-2 flex items-center text-xs bg-gray-200 w-fit p-2 border border-1 border-gray-400 rounded-lg"
                        >
                          <div
                            className="pr-2 border-r-2 border-gray-400 mr-2 cursor-pointer"
                            onClick={() => removeSelectedAttempt(attempt?.id)}
                          >
                            x
                          </div>
                          {attempt?.id} - {attempt.month}, {attempt.year}
                        </div>
                      ))}
                  {(!feesNew?.course_type?.attempts ||
                    selectedAttempts.length === 0) && (
                    <span className="text-gray-500 col-span-4">
                      No selected attempts
                    </span>
                  )}
                </div>
              </pre>

              <pre className="w-full grid grid-cols-3 items-center">
                <span className="font-extrabold">Fees Structure:</span>
                <div className="col-span-2 w-3/4">
                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    id="feesStructure"
                    onChange={handleFileChange}
                  />
                  <label
                    htmlFor="feesStructure"
                    className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                  >
                    Replace PDF
                  </label>
                  {selectedFile ? (
                    <p className="mt-2 text-center">{selectedFile.name}</p>
                  ) : feesNew.fees_structure ? (
                    <p className="mt-2 text-center">
                      <a
                        href={feesNew.fees_structure}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline cursor-pointer"
                      >
                        {feesNew.fees_structure.substring(76)}{' '}
                      </a>
                    </p>
                  ) : null}
                </div>
              </pre>

              <pre className="w-full grid grid-cols-3 items-center">
                <span className="font-extrabold">Syllabus:</span>
                <div className="col-span-2 w-3/4">
                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    id="syllabus"
                    onChange={handleSyllabusChange}
                  />
                  <label
                    htmlFor="syllabus"
                    className="p-2 px-4 cursor-pointer text-white bg-green-600 hover:bg-green-500 rounded-lg block text-center"
                  >
                    Replace PDF
                  </label>
                  {selectedSyllabus ? (
                    <p className="mt-2 text-center truncate">
                      {selectedSyllabus.name}
                    </p>
                  ) : feesNew.syllabus ? (
                    <p className="mt-2 text-center truncate">
                      <a
                        href={feesNew.syllabus}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline cursor-pointer block w-full"
                      >
                        {feesNew.syllabus.substring(76)}
                      </a>
                    </p>
                  ) : null}
                </div>
              </pre>
            </div>
            <div className="my-4">
              {isUpdating
                ? (JSON.stringify(feesNew) != JSON.stringify(fees) ||
                    selectedFile ||
                    selectedSyllabus ||
                    selectedAttempts != orgAttempts) && (
                    <button className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white opacity-50 w-5/6">
                      Updating
                    </button>
                  )
                : (JSON.stringify(feesNew) != JSON.stringify(fees) ||
                    selectedFile ||
                    selectedSyllabus ||
                    selectedAttempts != orgAttempts) && (
                    <button
                      onClick={() => {
                        setIsUpdating(true);
                        updateSubmission();
                      }}
                      className="p-2 rounded-lg bg-blue-600 text-lg font-semibold text-white w-5/6"
                    >
                      Update
                    </button>
                  )}
              <button
                onClick={() => setIsModalOpen(true)}
                className="p-2 rounded-lg bg-red-600 text-lg font-semibold text-white w-5/6 my-4"
              >
                Delete
              </button>
            </div>
            <ToastContainer />
          </main>
        ) : (
          <div className="p-16 text-3xl font-extrabold">
            No Order Found With Id : {id}
          </div>
        )}
      </div>
    </div>
  );
}

export default SpecificFees;
