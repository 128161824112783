import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Doubt {
  id: string;
  submission: {
    test: {
      test_title: string;
    };
    user: {
      name: string;
    };
  };
  user: {
    name: string;
  };
  question: string;
  answer: string;
  doubt_status: boolean;
}

interface DoubtRowProps {
  doubt: Doubt;
  isEven: boolean;
}

const DoubtRow: React.FC<DoubtRowProps> = ({ doubt, isEven }) => {
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const navHandler = useNavigate();

  console.log(doubt);

  return (
    <div
      onClick={() => navHandler(`/doubt/${doubt.id}`)}
      className={`grid grid-cols-[1fr_1fr_1fr_1fr] gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-hidden`}
    >
      <div className=" p-4 text-base leading-6 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs border-t border-solid border-zinc-200 text-neutral-800">
        {doubt.submission.test.test_title +
          ' - ' +
          doubt.submission.user.name || '-'}
      </div>
      <div className=" p-4 text-base leading-6 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs border-t border-solid border-zinc-200 text-neutral-800">
        {doubt.user.name || '-'}
      </div>
      <div className=" p-4 text-base leading-6 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs border-t border-solid border-zinc-200 text-neutral-800">
        {doubt.question || '-'}
      </div>
      <div className=" p-4 flex justify-start items-center border-t border-solid border-zinc-200 text-neutral-800">
        {doubt.doubt_status ? (
          <img src="/tick.svg" alt="Approved" />
        ) : (
          <img src="/redCross.svg" alt="Rejected" />
        )}
      </div>
    </div>
  );
};

export default DoubtRow;
