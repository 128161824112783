import React from 'react';
import { useNavigate } from 'react-router-dom';

interface courseType{
    title : string
}
interface testSeriesType{
    title : string
}
interface attemptsType{
    month : string,
    year: number
}
interface Fees {
    id: string;
    attempts: attemptsType[];
    course_type : courseType;
    test_series_type: testSeriesType;
}

interface FeesRowProps {
    fees: Fees;
    isEven: boolean;
}

const FeesRow: React.FC<FeesRowProps> = ({ fees, isEven }) => {
    const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
    const navHandler = useNavigate()

    return (
        <div onClick={()=>(navHandler(`/fees-and-syllabus/${fees.id}`))} className={`grid grid-cols-3 gap-0 justify-center ${rowClass} text-left cursor-pointer`}>
            <div className="justify-center items-start px-3 py-5 text-base font-bold leading-6 text-blue-600 whitespace-nowrap bFees-t bFees-solid bFees-zinc-200 max-md:pr-5">
                {fees.test_series_type?.title}
            </div>
            <div className="justify-center items-start px-3 py-5 text-base leading-6 whitespace-nowrap bFees-t bFees-solid bFees-zinc-200 text-neutral-800 max-md:pr-5">
                {fees.course_type.title}
            </div>
            <div className="justify-center items-start px-3 py-4 text-base leading-6 bFees-t bFees-solid bFees-zinc-200 text-neutral-800 max-md:pr-5">
                {fees.attempts[0]?.month}, {fees.attempts[0]?.year}
                {fees.attempts.length>1 && <span className='text-bluee-600'>+ more</span>}
            </div>
        </div>
    );
};

export default FeesRow;