import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Teacher {
    id: number;
    first_name: string;
    last_name: string;
    role: string;
  }
  
  interface Submission {
    id: number;
    test: {
      test_title: string;
      subject: {
        id: number;
        title: string;
      };
    };
    user: {
      name: string;
    };
    assigned_teacher: Teacher | null;
    available_teachers: Teacher[];
    check_status: boolean;
    created_at: string
  }

export interface SubmissionsState {
  count: number;
  previous: string | null;
  next: string | null;
  current: number;
  results: Submission[]; 
}

const initialState: SubmissionsState = {
  count: 0,
  previous: null,
  next: null,
  results: [],
  current: 1,
};

export const SubmissionsSlice = createSlice({
  name: 'submissions',
  initialState,
  reducers: {
    setSubmissions: (state, action: PayloadAction<SubmissionsState>) => {
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
      state.results = action.payload.results;
      state.current = action.payload.current || 1;
    },
    setSubmissionResults: (state, action: PayloadAction<Submission[]>) => {
      state.results = action.payload;
    },
    setCurrentSubmissionPage: (state, action: PayloadAction<number>) => {
      state.current = action.payload;
    },
    updateSubmission: (state, action: PayloadAction<Submission>) => {
      const index = state.results.findIndex(sub => sub.id === action.payload.id);
      if (index !== -1) {
        state.results[index] = action.payload; // For Updating the teacher change
      }
    },
  },
});

export const { setSubmissions, setCurrentSubmissionPage, setSubmissionResults, updateSubmission } = SubmissionsSlice.actions;

export default SubmissionsSlice.reducer;
