import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SubjectState {
    count: number;
    previous: string | null;
    next: string | null;
    current: number;
    results: any[]; // Update with appropriate type for results array
}

const initialState: SubjectState = {
    count: 0,
    previous: null,
    next: null,
    results: [],
    current: 1
};

export const subjectsSlice = createSlice({
    name: 'subjects', 
    initialState,
    reducers: {
      setSubject: (state, action: PayloadAction<SubjectState>) => {
        state.count = action.payload.count;
        state.previous = action.payload.previous;
        state.next = action.payload.next;
        state.results = action.payload.results; 
        state.current = action.payload.current || 1; 
      },
      setCurrentSubjectPage: (state, action: PayloadAction<number>) => {
        state.current = action.payload;
      },
    },
  });
  

export const { setSubject, setCurrentSubjectPage } = subjectsSlice.actions;

export default subjectsSlice.reducer;