import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import OrdersTable from './OrdersTable';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { useNavigate } from 'react-router-dom';
import BaseUrl from '../../BaseUrl';
import { RootState } from '../../redux/store';
import { setCurrent, setOrders } from '../../redux/slices/orders';
import { setAccess } from '../../redux/slices/auth';
import Loader from '../Loader';

function Orders() {
  const navHandler = useNavigate();
  const token = useAppSelector((state: RootState) => state.auth.access);
  const prev = useAppSelector((state: RootState) => state.orders.previous);
  const next = useAppSelector((state: RootState) => state.orders.next);
  const current = useAppSelector((state: RootState) => state.orders.current);
  const dispatch = useAppDispatch();
  const [number, setNumber] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState<number | undefined>();
  const [isFiltering, setIsFiltering] = useState(false);

  const fetchOrders = () => {
    if (!token) {
      navHandler('/login');
      return;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Set up URL parameters for API request
    let params = new URLSearchParams();

    if (isFiltering) {
      params.append('page', '1'); // Reset to page 1 when filtering
    } else {
      params.append('page', String(current)); // Use current page for pagination
    }

    // Apply filters only if they are set
    if (number) params.append('user_phone', number);
    if (orderStatus) params.append('order_status', orderStatus);
    if (createdAt) params.append('created_at', createdAt);

    setIsLoading(true);

    BaseUrl.get(`filter-order/?${params.toString()}`, config)
      .then((res) => {
        const modifiedData = {
          ...res.data,
          current: res.data.current, // Use current page from API response
        };
        dispatch(setOrders(modifiedData)); // Dispatch the result to Redux or any state management
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          dispatch(setAccess('')); // Handle token expiration
        }
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false); // Stop the loading state
      });
  };

  // Debounced input handler to avoid frequent API calls
  const handleInputChange = () => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout); // Clear any existing debounce timer
    }

    setDebounceTimeout(
      window.setTimeout(() => {
        setIsFiltering(true); // Set filtering to true
        dispatch(setCurrent(1)); // Reset pagination to page 1
      }, 300) // 300ms delay to debounce input
    );
  };

  // Monitor changes in filter inputs (number, orderStatus, createdAt)
  useEffect(() => {
    handleInputChange(); // Trigger debounce when any filter input changes
  }, [number, orderStatus, createdAt]);

  // Effect to handle API fetch when filtering is active
  useEffect(() => {
    if (isFiltering) {
      fetchOrders(); // Fetch orders with filters
    }
  }, [isFiltering]);

  // Effect to handle page change (only fetch when not filtering)
  useEffect(() => {
    if (!isFiltering) {
      fetchOrders(); // Fetch orders when changing pages
    }
  }, [current]);

  // Reset filtering state after fetching
  useEffect(() => {
    if (isFiltering) {
      setIsFiltering(false); // Reset filtering state after fetch
    }
  }, [isFiltering]);

  // Clear all filters and reset the page to 1
  const clearFilters = () => {
    setNumber(''); // Clear phone filter
    setOrderStatus(''); // Clear order status filter
    setCreatedAt(''); // Clear created date filter
    setIsFiltering(false); // Reset filtering
    fetchOrders(); // Fetch orders without any filters
  };

  return (
    <div className="text-sm grid grid-cols-1 lg:grid-cols-5">
      <Sidebar activeLabel="Orders" />
      <div className="col-span-1 md:col-span-4 bg-[#F4F6F9] w-full min-h-[100vh]">
        <header className="flex gap-5 justify-between px-4 md:px-11 py-6 w-full border-b border-solid border-zinc-200 flex-wrap">
          <div className="flex flex-col sm:flex-row mx-auto sm:mx-0 gap-5 justify-between my-auto whitespace-nowrap">
            <h1 className="text-center sm:py-1.5 text-2xl leading-7 pr-4 border-r border-solid border-zinc-300 text-neutral-800">
              Orders
            </h1>
            <nav className="flex gap-2.5 my-auto text-base leading-6 text-gray-500">
              <a href="/" className="grow text-blue-600">
                Home
              </a>
              <span>›</span>
              <a href="/orders" className="text-blue-600">
                Orders
              </a>
              <span>›</span>
              <span>Orders</span>
            </nav>
          </div>
        </header>
        <main className="flex flex-col p-4 md:p-8 w-full">
          <div className="my-6">
            <div className="flex justify-between items-center mb-4">
              <p className="text-lg font-semibold text-gray-700">Filters</p>
              <button
                onClick={clearFilters}
                className="px-4 py-2 bg-red-500 text-white text-sm font-medium rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Clear All Filters
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-end">
              <div>
                <label
                  htmlFor="number"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Phone number:
                </label>
                <input
                  type="number"
                  id="number"
                  name="number"
                  placeholder="Enter phone number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="orderStatus"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Order Status:
                </label>
                <select
                  id="orderStatus"
                  name="orderStatus"
                  value={orderStatus}
                  onChange={(e) => setOrderStatus(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">----</option>
                  <option value="Completed">Completed</option>
                  <option value="Canceled">Cancelled</option>
                  <option value="Pending">Pending</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="createdAt"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Created At
                </label>
                <input
                  type="date"
                  id="createdAt"
                  name="createdAt"
                  value={createdAt}
                  onChange={(e) => setCreatedAt(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          <div className="relative">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75 z-10">
                <Loader />
              </div>
            )}
            <OrdersTable />
          </div>
        </main>
        <div className="flex gap-2 w-full justify-center items-center m-4">
          {prev && (
            <button
              onClick={() => dispatch(setCurrent(current - 1))}
              disabled={isLoading}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 ${
                isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              {'<'}
            </button>
          )}
          <div className="px-2 py-1 rounded-lg border border-2 border-blue-400 text-xl text-white font-semibold bg-blue-600 cursor-pointer">
            {current}
          </div>
          {next && (
            <button
              onClick={() => dispatch(setCurrent(current + 1))}
              disabled={isLoading}
              className={`px-2 py-1 rounded-lg border border-2 border-gray-400 text-xl font-semibold bg-gray-200 ${
                isLoading ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              {'>'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Orders;
