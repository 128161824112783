import React from 'react';
import { useAppSelector } from '../../redux/hooks/hooks';
import SubjectRow from './SubjectRow';

const SubjectsTable: React.FC = () => {
  const subjects = useAppSelector((state) => state.subjects.results) || [];

  return (
    <section>
      <div className="flex flex-col justify-center bg-white rounded shadow max-md:max-w-full">
        <div className="grid grid-cols-4 text-left w-full text-base font-bold leading-6 text-blue-600">
          <p className="col-span-2 p-4">Subject Title</p>
        </div>

        {subjects.length > 0 ? (
          subjects.map((subject, index) => (
            <SubjectRow
              key={index}
              subject={subject}
              isEven={index % 2 === 0}
            />
          ))
        ) : (
          <div className="p-4 text-gray-500">
            No subjects found for this course type.
          </div>
        )}
      </div>
    </section>
  );
};

export default SubjectsTable;
