import React from 'react';
import { useNavigate } from 'react-router-dom';

interface User {
  id: string;
  phone: string;
  name: string;
  // gender: string;
  email: string;
}

interface UserRowProps {
  user: User;
  isEven: boolean;
}

const UserRow: React.FC<UserRowProps> = ({ user, isEven }) => {
  const rowClass = isEven ? 'bg-black bg-opacity-10' : '';
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/user/${user.id}`)}
      className={`grid grid-cols-8 gap-0 justify-center ${rowClass} text-left cursor-pointer overflow-x-auto max-md:flex max-md:flex-col`}
    >
      {/* For larger screens (md and up) */}
      <div className="p-4 text-base font-bold leading-6 text-blue-600 whitespace-nowrap border-t border-solid border-zinc-200 max-md:hidden">
        {user.id}
      </div>
      <div className="col-span-2 p-4 text-base leading-6 text-neutral-800 whitespace-nowrap border-t border-solid border-zinc-200 max-md:hidden">
        {user.phone || '-'}
      </div>
      <div className="col-span-2 p-4 text-base leading-6 text-neutral-800 whitespace-nowrap border-t border-solid border-zinc-200 max-md:hidden">
        {user.name || '-'}
      </div>
      {/* <div className="col-span-2 p-4 text-base leading-6 text-neutral-800 whitespace-nowrap border-t border-solid border-zinc-200 max-md:hidden">
        {user.gender || '-'}
      </div> */}
      <div className="col-span-3 p-4 text-base leading-6 text-neutral-800 whitespace-nowrap border-t border-solid border-zinc-200 max-md:hidden">
        {user.email || '-'}
      </div>

      {/* For smaller screens (below md) */}
      <div className="hidden max-md:flex flex-col p-4 space-y-2 text-neutral-800 border-t border-solid border-zinc-200">
        <p>
          <strong>Id:</strong> {user.id}
        </p>
        <p>
          <strong>Phone:</strong> {user.phone || '-'}
        </p>
        <p>
          <strong>Name:</strong> {user.name || '-'}
        </p>
        {/* <p>
          <strong>Gender:</strong> {user.gender || '-'}
        </p> */}
        <p>
          <strong>Email:</strong> {user.email || '-'}
        </p>
      </div>
    </div>
  );
};

export default UserRow;
