import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthState, setAccess } from '../redux/slices/auth';
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks';

interface MenuItem {
  label: string;
  icon: string;
  route?: string;
}

interface MenuSection {
  title: string;
  items: MenuItem[];
}

const menuSections: MenuSection[] = [
  {
    title: 'Client',
    items: [{ label: 'Users', icon: '/navDot.svg', route: '/users' }],
  },
  {
    title: 'Coupons',
    items: [{ label: 'Coupons', icon: '/navDot.svg', route: '/coupons' }],
  },
  {
    title: 'Courses/Subjects',
    items: [{ label: 'Subjects', icon: '/navDot.svg', route: '/subjects' }],
  },
  {
    title: 'Employees',
    items: [{ label: 'Employees', icon: '/navDot.svg', route: '/employees' }],
  },
  {
    title: 'Orders',
    items: [{ label: 'Orders', icon: '/navDot.svg', route: '/orders' }],
  },
  {
    title: 'Promotions',
    items: [{ label: 'Promotions', icon: '/navDot.svg', route: '/promotions' }],
  },
  {
    title: 'Report',
    items: [
      { label: 'Doubts', icon: '/navDot.svg', route: '/doubts' },
      { label: 'Submissions', icon: '/navDot.svg', route: '/submissions' },
    ],
  },
  {
    title: 'Testseries',
    items: [
      // {
      //   label: 'Fee structure and syllabus',
      //   icon: '/navDot.svg',
      //   route: '/fees-and-syllabus',
      // },
      {
        label: 'Test series types',
        icon: '/navDot.svg',
        route: '/test-series-types',
      },
      { label: 'Test series', icon: '/navDot.svg', route: '/test-series' },
      { label: 'Tests', icon: '/navDot.svg', route: '/tests' },
    ],
  },
  // {
  //   title: 'Transactions',
  //   items: [
  //     {
  //       label: 'Payment Information',
  //       icon: '/navDot.svg',
  //       route: '/payment-information',
  //     },
  //     { label: 'Transactions', icon: '/navDot.svg', route: '/transactions' },
  //   ],
  // },
];

interface Props {
  activeLabel: string;
}

const Sidebar: React.FC<Props> = ({ activeLabel }) => {
  const navHandler = useNavigate();
  const phone = useAppSelector((state) => state.auth.phone);
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.auth.role);

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="lg:hidden fixed top-0 left-0 z-20 p-4">
        <button onClick={toggleSidebar} className="text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>

      {/* Sidebar */}
      <aside
        className={`fixed top-0 z-20 flex flex-col w-1/5 overflow-x-hidden overflow-y-auto h-full bg-[#343A40] shadow-xl transition-transform duration-300 ease-in-out    ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0 lg:block`}
      >
        <div className="flex flex-col grow pb-20 w-full h-full">
          <div className="flex gap-2 items-center px-5 py-4 text-xl font-light leading-8 border-b border-gray-600 text-white">
            <img
              loading="lazy"
              src="/logo.png"
              className="shrink-0 shadow-lg aspect-square w-[33px]"
              alt="logo"
            />
            <div>Gradehunt admin</div>
          </div>

          <div className="flex gap-2.5 items-center px-3.5 py-5 border-b border-gray-600">
            <div className="text-4xl leading-10 text-white"></div>
            <div className="flex-auto mt-3 text-base leading-6 text-neutral-300">
              {phone}
            </div>
          </div>

          <nav className="flex flex-col px-6 text-neutral-300">
            {/* <div className="flex gap-2.5 whitespace-nowrap">
              <img src="/dashboard.svg" alt="dashboard" />
              <div>Dashboard</div>
            </div> */}

            {role === 'admin' && (
              <div className="text-left">
                {menuSections.map((section, index) => (
                  <React.Fragment key={index}>
                    <div className="mt-8 text-md leading-5 text-white text-opacity-30">
                      {section.title}
                    </div>
                    {section.items.map((item, itemIndex) => (
                      <div
                        key={itemIndex}
                        onClick={() => navHandler(item.route || '/')}
                        className={`flex cursor-pointer gap-4 p-2 my-2 items-center ${
                          item.label === activeLabel &&
                          'bg-blue-600 rounded shadow'
                        }`}
                      >
                        <img src={item.icon} alt="item icon" />
                        <div className="text-base leading-6">{item.label}</div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            )}

            {role === 'teacher' && (
              <div className="text-left">
                <React.Fragment>
                  <div className="mt-8 text-xs leading-5 text-white text-opacity-30">
                    Report
                  </div>
                  <div
                    onClick={() => navHandler('/submissions')}
                    className="flex cursor-pointer gap-4 p-2 my-2 items-center bg-blue-600 rounded shadow"
                  >
                    <img src="/navDot.svg" alt="item icon" />
                    <div className="text-base leading-6">Submissions</div>
                  </div>
                </React.Fragment>
              </div>
            )}

            {role === 'doubt_solver' && (
              <div className="text-left">
                <React.Fragment>
                  <div className="mt-8 text-xs leading-5 text-white text-opacity-30">
                    Report
                  </div>
                  <div
                    onClick={() => navHandler('/doubts')}
                    className="flex cursor-pointer gap-4 p-2 my-2 items-center bg-blue-600 rounded shadow"
                  >
                    <img src="/navDot.svg" alt="item icon" />
                    <div className="text-base leading-6">Doubts</div>
                  </div>
                </React.Fragment>
              </div>
            )}

            <div className="mx-auto my-10">
              <button
                className="cursor-pointer rounded-full px-6 py-2 bg-red-600 text-white"
                onClick={() => {
                  dispatch(setAccess(''));
                  navHandler('/login');
                }}
              >
                Logout
              </button>
            </div>
          </nav>
        </div>
      </aside>

      {isOpen && (
        <div
          onClick={toggleSidebar}
          className="fixed inset-0 z-10 bg-black bg-opacity-50 lg:hidden"
        ></div>
      )}
    </div>
  );
};

export default Sidebar;
